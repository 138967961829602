import type { AxisScaleOutput } from "@visx/axis";
import type { ScaleConfig } from "@visx/scale";
import { lazy } from "react";

import type { ChartProps } from "./chart.lazy";

export const Chart = lazy(() =>
  import("./chart.lazy").then(({ Chart }) => ({ default: Chart })),
) as <
  XScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  YScaleConfig extends ScaleConfig<AxisScaleOutput, any, any>,
  Datum extends object,
>(
  props: ChartProps<XScaleConfig, YScaleConfig, Datum>,
) => JSX.Element;
