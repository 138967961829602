import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_god/dev/")({
  beforeLoad: async () => {
    throw redirect({
      to: "/dev/logs",
      replace: true,
    });
  },
});
