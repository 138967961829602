import {
  IconArrowNarrowRight,
  IconEdit,
  IconTrashX,
} from "@tabler/icons-react";
import { NavigateOptions, useNavigate } from "@tanstack/react-router";
import { ColumnHelper } from "@tanstack/react-table";

import { brandIcons, specialChars } from "../../assets";
import {
  cellKinds,
  checkCell,
  imageCell,
  menuCell,
  stackCell,
} from "../../components";
import { RoleItem, RolePermission, useAuth, usePrefetchRole } from "../../data";

const byDisplay = {
  account: (c: ColumnHelper<RoleItem>) => [
    c.accessor(
      "account.product",
      imageCell({
        header: "",
        icons: brandIcons,
      }),
    ),
    c.accessor(
      "account.name",
      stackCell({
        header: "Account",
        detail: (a) => [a.account?.customer?.name],
        always: true,
      }),
    ),
  ],
  customer: (c: ColumnHelper<RoleItem>) => [
    c.accessor(
      "customer.name",
      stackCell({
        header: "Name",
        detail: () => [],
      }),
    ),
  ],
  user: (c: ColumnHelper<RoleItem>) => [
    c.accessor(
      "user.name",
      stackCell({
        header: "Name",
        detail: (data) => [data.user.email],
        always: true,
      }),
    ),
  ],
};

export const roleColumns =
  ({
    display,
    prefetchRole,
    hasTeamPermission,
    navigate,
    current,
    permissions,
  }: {
    display: "account" | "customer" | "user";
    prefetchRole: ReturnType<typeof usePrefetchRole>;
    hasTeamPermission: ReturnType<typeof useAuth>["hasTeamPermission"];
    navigate: ReturnType<typeof useNavigate>;
    current: NavigateOptions;
    permissions: RolePermission[];
  }) =>
  (c: ColumnHelper<RoleItem>) => [
    ...byDisplay[display](c),
    c.accessor(
      (v) => v.permissions.includes("read"),
      checkCell({ header: "View" }),
    ),
    c.accessor(
      (v) => v.permissions.includes("write"),
      checkCell({ header: "Edit" }),
    ),
    c.accessor(
      (v) => v.permissions.includes("install"),
      checkCell({ header: "Install" }),
    ),
    c.accessor(
      (v) => v.permissions.includes("admin"),
      checkCell({ header: "Manage" }),
    ),
    c.accessor("permissions", {
      header: "Permission",
      meta: {
        className: cellKinds.hiddenxl,
      },
      cell: (v) => {
        const value = v.getValue();
        if (value.includes("admin")) return "Manage";
        if (value.includes("install") && value.includes("write"))
          return "Install & Edit";
        if (value.includes("install")) return "Install";
        if (value.includes("write")) return "Edit";
        if (value.includes("read")) return "View";

        return specialChars.endash;
      },
    }),
    c.display(
      menuCell({
        onClick: ({ id }) => prefetchRole(id),
        items: [
          display === "account"
            ? {
                icon: IconArrowNarrowRight,
                text: "View Account",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    to: "/accounts/$accountId",
                    params: { accountId: r.account!.id },
                  }),
              }
            : undefined,
          display === "customer"
            ? {
                icon: IconArrowNarrowRight,
                text: "View Customer",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    to: "/customers/$customerId",
                    params: { customerId: r.customer!.id },
                  }),
              }
            : undefined,
          display === "user" && hasTeamPermission("admin")
            ? {
                icon: IconArrowNarrowRight,
                text: "View User",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    to: "/users/$userId",
                    params: { userId: r.user.id },
                  }),
              }
            : undefined,
          permissions.includes("admin")
            ? {
                icon: IconEdit,
                text: "Update Permissions",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    ...current,
                    search: { action: "edit", role: r.id },
                    replace: true,
                  }),
              }
            : undefined,
          permissions.includes("admin")
            ? {
                icon: IconTrashX,
                text: "Remove Role",
                variant: "danger",
                onClick: (r) =>
                  navigate({
                    ...current,
                    search: { action: "delete", role: r.id },
                    replace: true,
                  }),
              }
            : undefined,
        ],
      }),
    ),
  ];
