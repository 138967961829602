import { dayOrder, pluralize, range } from "@joy/shared-utils";

import {
  CheckInputField,
  ComboField,
  Field,
  Label,
  RadioField,
  SlideContent,
  fieldParts,
} from "../../components";
import { useUpdateGaugeSchedule } from "../../data";

const everyLabels = {
  Week: {
    title: "Weekly",
    description: "Every week on selected days",
  },
  Fortnight: {
    title: "Fortnightly",
    description: "On the 1st & 14th of every month",
  },
  Month: {
    title: "Monthly",
    description: "On the 21st of every month",
  },
};

export const GaugeScheduleContent = ({ gaugeId }: { gaugeId: string }) => {
  const { data, error, form, validators } = useUpdateGaugeSchedule(gaugeId);

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the gauge schedule below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="every"
        validators={validators.every}
        children={(field) => (
          <RadioField
            field={field}
            label="Schedule Type"
            options={validators.every.onSubmit._def.values}
            optionLabel={(o) => (
              <div>
                <div>{everyLabels[o].title}</div>
                <div className="text-xs text-gray-500">
                  {everyLabels[o].description}
                </div>
              </div>
            )}
          />
        )}
      />
      <form.Subscribe
        selector={(v) => v.values.every === "Week"}
        children={(showDays) =>
          showDays ? (
            <Field>
              <Label>Upload Days</Label>
              <div className={fieldParts.checks}>
                {dayOrder.map((day) => (
                  <form.Field
                    key={day}
                    name={`days.${day}`}
                    children={(field) => (
                      <CheckInputField field={field} label={day} />
                    )}
                  />
                ))}
              </div>
            </Field>
          ) : null
        }
      />
      <form.Field
        name="timezone"
        validators={validators.timezone}
        children={(field) => (
          <ComboField
            field={field}
            label="Timezone"
            options={Intl.supportedValuesOf("timeZone").map((value) => ({
              value,
            }))}
            accessors={[(o) => o.value]}
            optionKey={(o) => o.value}
            optionLabel={(o) => o.value}
            immediate
          />
        )}
      />
      <form.Field
        name="startTime"
        validators={validators.time}
        children={(field) => (
          <ComboField
            field={field}
            label="Start Time"
            options={range(1, 23).map((hour) => ({ hour }))}
            accessors={[(o) => [`${o.hour}`, `${o.hour % 12}`]]}
            optionKey={(o) => o.hour}
            optionLabel={(o) =>
              o.hour === 12
                ? "midday"
                : `${o.hour % 12} ${o.hour >= 12 ? "pm" : "am"}`
            }
            immediate
          />
        )}
      />
      <form.Field
        name="frequency"
        validators={validators.frequency}
        children={(field) => (
          <ComboField
            field={field}
            label="Frequency"
            options={[
              { value: 1 },
              { value: 2 },
              { value: 4 },
              { value: 12 },
              { value: 24 },
            ]}
            accessors={[(o) => [`${o.value}`]]}
            optionKey={(o) => o.value}
            optionLabel={(o) =>
              o.value === 24 ? "Once" : `Every ${pluralize(o.value, "hour")}`
            }
            immediate
          />
        )}
      />
      <form.Subscribe
        selector={(v) => v.values.frequency.value !== 24}
        children={(showEndTime) =>
          showEndTime ? (
            <form.Field
              name="endTime"
              validators={validators.time}
              children={(field) => (
                <ComboField
                  field={field}
                  label="End Time"
                  options={range(1, 23).map((hour) => ({ hour }))}
                  accessors={[(o) => [`${o.hour}`, `${o.hour % 12}`]]}
                  optionKey={(o) => o.hour}
                  optionLabel={(o) =>
                    o.hour === 12
                      ? "midday"
                      : `${o.hour % 12} ${o.hour >= 12 ? "pm" : "am"}`
                  }
                  immediate
                />
              )}
            />
          ) : null
        }
      />
    </SlideContent>
  );
};
