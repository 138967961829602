import { TextField } from "../../components";
import { AccountConfigContentProps } from "../../data";

export const SmartFillContent = ({
  form,
  validators,
}: AccountConfigContentProps) => (
  <>
    <form.Field
      name="reference"
      validators={validators.reference}
      children={(field) => (
        <TextField field={field} label="SmartFill Reference" autoFocus />
      )}
    />
    <form.Field
      name="secret"
      validators={validators.secret}
      children={(field) => <TextField field={field} label="SmartFill Secret" />}
    />
  </>
);
