import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useState } from "react";
import { z } from "zod";

import { Permission, permissions } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { request, requestFn } from "./base";
import { InviteUserDocument, InvitesDocument } from "./operations.generated";

export const invitesQuery = () =>
  infiniteQueryOptions({
    queryKey: ["invites"],
    queryFn: ({ pageParam }) =>
      request(InvitesDocument, { limit: 100, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.invites.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.invites.invites),
  });

const validation = {
  email: z.string().email(),
  jlteam: z.enum(permissions),
};

const inviteUserFn = requestFn(InviteUserDocument);

export const useInviteUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: inviteUserFn,
    onSuccess: async () => {
      queryClient.invalidateQueries(invitesQuery());
      await navigate({
        to: "/users",
      });
    },
  });

  const [confirming, setConfirming] = useState(false);
  const form = useForm({
    defaultValues: {
      email: "",
      jlteam: "user" as Permission,
      confirmed: false,
    },
    onSubmit: async ({ value: { email, jlteam, confirmed } }) => {
      if (jlteam !== "user" && !confirmed) {
        setConfirming(true);
        return;
      }

      await mutateAsync({
        email,
        jlteam: jlteam === "user" ? undefined : jlteam,
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { confirming, setConfirming, error, form, validators };
};
