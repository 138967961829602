import { IconAlertTriangle } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";

import { ModalContent } from "../../components";
import {
  buildAssociationError,
  customerAccountsQuery,
  useDeleteCustomer,
} from "../../data";

export const CustomerDeleteContent = ({
  customerId,
}: {
  customerId: string;
}) => {
  const accounts = useSuspenseInfiniteQuery(customerAccountsQuery(customerId));
  const { data, error, isPending, onDelete } = useDeleteCustomer(customerId);
  const accountsError = buildAssociationError(accounts, {
    label: "Customer",
    item: "account",
    action: "deleted",
  });

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete "${data.name}"?`}
      description={`Are you sure you want to delete "${data.name}"? This action cannot be undone.`}
      error={accountsError || error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Deleting..." }}
      buttons={[
        {
          text: "Delete",
          type: "submit",
          variant: "danger",
          disabled: !!accountsError,
          autoFocus: true,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
