import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { useAuth, useDeleteRole, userName } from "../../data";

export const RoleDeleteContent = ({ roleId }: { roleId: string }) => {
  const { data, error, isPending, onDelete } = useDeleteRole(roleId);
  const { email } = useAuth();
  const selfError =
    data.user?.email === email
      ? new Error("You cannot remove your own access.")
      : undefined;

  let entityName = "";
  switch (data.entityType) {
    case "account":
      entityName = data.account?.name || "";
      break;
    case "customer":
      entityName = data.customer?.name || "";
      break;
  }

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Remove access for ${userName(data.user)}?`}
      description={`Are you sure you want to remove ${userName(data.user)}'s access to ${entityName}? This action cannot be undone.`}
      error={error || selfError}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Removing..." }}
      buttons={[
        {
          text: "Remove",
          type: "submit",
          variant: "danger",
          autoFocus: true,
          disabled: !!selfError,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
