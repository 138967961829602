import { Transition } from "@headlessui/react";
import { IconArrowRight, IconLineDashed } from "@tabler/icons-react";
import clsx from "clsx";
import { useState } from "react";

import { keys } from "@joy/shared-utils";

type TreeItemProps = {
  label: string;
  value: any;
};

const TreeItem = ({ label, value }: TreeItemProps) => {
  const [show, setShow] = useState(false);
  const isObject = typeof value === "object";

  return (
    <div>
      <button
        className="flex items-center gap-1"
        onClick={() =>
          isObject ? setShow(!show) : navigator.clipboard.writeText(value)
        }
      >
        {isObject ? (
          <IconArrowRight className="size-4" rotate={show ? 90 : 0} />
        ) : (
          <IconLineDashed className="size-4" />
        )}
        <strong>{label}</strong> {!isObject ? value : ""}
      </button>
      {isObject && (
        <Transition show={show}>
          <Tree data={value} className="ml-2" />
        </Transition>
      )}
    </div>
  );
};

export type TreeProps = {
  data: object;
  className?: string;
};

export const Tree = ({ data, className }: TreeProps) => (
  <div className={clsx(className, "flex flex-col gap-1 text-sm")}>
    {keys(data).map((key) => (
      <TreeItem key={key} label={key} value={data[key]} />
    ))}
  </div>
);
