import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { InviteContent } from "./invite";
import { InvitesContent } from "./invites";

export const UsersActionPanel = () => {
  const { Slide } = useMatch({
    from: "/_admin/users/",
    select: (s) => {
      switch (s.search.action) {
        case "invite":
          return { Slide: InviteContent };
        case "invites":
          return { Slide: InvitesContent };
      }

      return {};
    },
  });

  return <Actions slide={Slide && <Slide />} />;
};
