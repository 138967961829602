import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { StatsHeader, TablePage } from "../../../../components";
import {
  listLoader,
  product,
  productsQuery,
  usePrefetchProduct,
} from "../../../../data";
import { useTable } from "../../../../hooks";
import { ProductsActionPanel, productColumns } from "../../../../panels";

const validateSearch = z.object({
  action: z.enum(["create", "edit", "delete"]).optional(),
  product: z.string().optional(),
});

export const Route = createFileRoute("/_admin/library/products/")({
  validateSearch,
  loader: async ({ context }) =>
    listLoader(context.queryClient, productsQuery()),
  component: Component,
});

export function Component() {
  const navigate = useNavigate();
  const prefetchProduct = usePrefetchProduct();

  const products = useTable(productsQuery(), {
    columnDefs: productColumns({ prefetchProduct, navigate }),
    create: {
      to: "/library/products",
      search: { action: "create" },
      replace: true,
    },
    word: product,
  });

  return (
    <TablePage {...products}>
      <StatsHeader stats={products.stats} />
      <ProductsActionPanel />
    </TablePage>
  );
}
