import { CellContext } from "@tanstack/react-table";

import { specialChars } from "../../assets";
import { Time } from "../time";
import { cellKinds } from "./parts";

export const CellDate = ({
  value,
}: {
  value: string | Date | null | undefined;
}) =>
  value ? (
    <>
      <Time className="hidden sm:inline" date={value} />
      <Time className="sm:hidden" date={value} options={{ format: "1" }} />
    </>
  ) : (
    specialChars.endash
  );

export const dateCell = ({
  header,
  kind = "squash",
}: {
  header: string;
  kind?: keyof typeof cellKinds;
}) => ({
  header,
  meta: { className: cellKinds[kind] },
  cell: (v: CellContext<any, string | Date | null | undefined>) => (
    <CellDate value={v.getValue()} />
  ),
});
