import {
  IconCloud,
  IconCloudCheck,
  IconExternalLink,
} from "@tabler/icons-react";
import clsx from "clsx";

import { mapOrEmpty } from "@joy/shared-utils";

import {
  Field,
  FormError,
  FormSuccess,
  Label,
  LinkButton,
  cellKinds,
  cellVariants,
  tableParts,
  text,
} from "../../components";
import { UploadGaugesContentProps } from "../../data";

const UploadStatus = ({
  status,
  error,
}: UploadGaugesContentProps["mutations"][number]) => {
  switch (status) {
    case "success":
      return <FormSuccess icon={IconCloudCheck} text="Created" />;
    case "error":
      return <FormError error={error} />;
    default:
      return (
        <FormSuccess
          icon={IconCloud}
          text="Creating..."
          className="animate-pulse text-gray-500"
        />
      );
  }
};

export const GaugeUploadResult = ({ mutations }: UploadGaugesContentProps) => {
  return (
    <Field>
      <Label>Gauges to create ({mutations.length}):</Label>
      <table className={tableParts.table}>
        <thead>
          <tr>
            <th className={clsx(text, cellKinds.title)}>IMEI</th>
            <th className={clsx(text, cellKinds.title)}>Product</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {mapOrEmpty(
            mutations,
            (item) => (
              <tr key={item.variables.id}>
                <td className={clsx(cellVariants.base, "px-3 py-2")}>
                  <LinkButton
                    kind="link"
                    icon={
                      item.status === "success" ? IconExternalLink : undefined
                    }
                    variant={item.status === "success" ? "action" : "cancel"}
                    disabled={item.status !== "success"}
                    text={item.variables.id}
                    target="_blank"
                    to="/gauges/$gaugeId"
                    params={{ gaugeId: item.variables.id || "" }}
                  />
                </td>
                <td className={clsx(cellVariants.base, "px-3 py-2")}>
                  {item.variables.fields.technology}
                </td>
                <td className={clsx(cellVariants.base, "px-3 py-2")}>
                  <UploadStatus {...item} />
                </td>
              </tr>
            ),
            <tr>
              <td colSpan={4} className={clsx(cellVariants.base, "px-3 py-6")}>
                <p className="text-center font-semibold italic text-gray-400">
                  No gauges to create.
                </p>
              </td>
            </tr>,
          )}
        </tbody>
      </table>
    </Field>
  );
};
