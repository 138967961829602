import { rankItem } from "@tanstack/match-sorter-utils";
import { FilterFn } from "@tanstack/react-table";
import { z } from "zod";

export const sortSchema = z.array(
  z.object({ id: z.string(), desc: z.boolean() }),
);

export const filterSchema = z.string();

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value, { threshold: 2 });
  addMeta({
    itemRank,
  });

  return itemRank.passed;
};
