import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import {
  accountQuery,
  rolesQuery,
  useAuth,
  usePrefetchRole,
  user,
} from "../../data";
import { useTable } from "../../hooks";
import { roleColumns } from "../role";

export const AccountUsersPanel = ({ accountId }: { accountId: string }) => {
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(accountQuery(accountId));

  const roles = useTable(rolesQuery(accountId, "account"), {
    columnDefs: roleColumns({
      prefetchRole,
      hasTeamPermission,
      navigate,
      display: "user",
      current: { to: "/accounts/$accountId", params: { accountId } },
      permissions: data.permissions,
    }),
    create: data.permissions.includes("admin")
      ? {
          to: "/accounts/$accountId",
          search: { action: "add" },
        }
      : undefined,
    word: user,
  });

  return <TablePage {...roles} />;
};
