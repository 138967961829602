export type Column = { type: "column" | "static" | "empty"; value: string };

export type Workbook = {
  sheets: string[];
  data: Record<
    string,
    {
      columns: Column[];
      rows: Record<string, any>[];
    }
  >;
};

export const loadWorkbook = async (file: File): Promise<Workbook> => {
  const data = await file.arrayBuffer();
  const xlsx = await import("xlsx");

  const wb = xlsx.read(data);

  return {
    sheets: wb.SheetNames,
    data: Object.fromEntries(
      wb.SheetNames.map((name) => {
        const headers = xlsx.utils.sheet_to_json(wb.Sheets[name]!, {
          header: 1,
        })[0] as string[];
        const rows = xlsx.utils.sheet_to_json(wb.Sheets[name]!) as Record<
          string,
          any
        >[];

        return [
          name,
          {
            rows,
            columns: headers.map((value) => ({
              type: "column",
              value,
            })),
          },
        ];
      }),
    ),
  };
};
