import { SlideContent, TextField } from "../../components";
import { useCreateDocument } from "../../data";

export const DocumentCreateContent = () => {
  const { error, form, validators } = useCreateDocument();

  return (
    <SlideContent
      title="Add document"
      description="Get started by filling in the information below to create your new document."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Creating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Create", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="id"
        validators={validators.id}
        children={(field) => (
          <TextField field={field} label="Document ID" autoFocus />
        )}
      />
      <form.Field
        name="title"
        validators={validators.title}
        children={(field) => <TextField field={field} label="Title" />}
      />
    </SlideContent>
  );
};
