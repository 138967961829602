import { format } from "date-fns";
import { ReactNode, useEffect, useState } from "react";

import {
  DisplayUser,
  TimeAgoOptions,
  refreshGap,
  timeAgo,
  userName,
} from "../data";
import { Tooltip } from "./tooltip";

export type TimeProps = {
  date: string | Date | null | undefined;
  className?: string;
  options?: TimeAgoOptions;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export const Time = ({
  date,
  className,
  options,
  prefix,
  suffix,
}: TimeProps) => {
  const [refresh, setRefresh] = useState(0);
  const [detail, setDetail] = useState(() => (date ? format(date, "PPp") : ""));
  const [display, setDisplay] = useState(() => timeAgo(date, options));
  useEffect(() => {
    setDetail(date ? format(date, "PPPp") : "");
    setDisplay(timeAgo(date, options));

    const ms = refreshGap(date);
    const timeout = ms && setTimeout(() => setRefresh((r) => r + 1), 60000);
    return () => clearTimeout(timeout);
  }, [refresh, date, options?.clean, options?.fallback, options?.format]);

  return (
    <span className={className}>
      {prefix}{" "}
      <Tooltip className="underline decoration-dotted" label={detail}>
        {display}
      </Tooltip>{" "}
      {suffix}
    </span>
  );
};

export const EntityCreated = ({ createdAt }: { createdAt: string | Date }) => (
  <Time date={createdAt} prefix="Created" />
);

export const EntityUpdated = ({
  updatedAt,
  updatedBy,
}: {
  updatedAt: string | Date;
  updatedBy: DisplayUser;
}) => (
  <Time
    date={updatedAt}
    prefix="Updated"
    suffix={`by ${userName(updatedBy)}`}
  />
);
