import { SetStateAction, useReducer } from "react";
import { Schema } from "zod";

type StorageType = "local" | "session";

export type StorageProps<V> = {
  key: string;
  storage: StorageType;
  schema: Schema<V>;
  defaultValue: V;
};

const byStorage = (storage: StorageType) => window[`${storage}Storage`];

const loadStorage =
  <V>({ storage, key, schema, defaultValue }: StorageProps<V>) =>
  (): V => {
    const value = byStorage(storage).getItem(key);
    if (value) {
      try {
        return schema.parse(JSON.parse(value));
      } catch (e) {
        console.error(e);
      }
    }

    return defaultValue;
  };

const saveStorage =
  <V>({ storage, key }: StorageProps<V>) =>
  (previous: V, next: SetStateAction<V>): V => {
    const value = next instanceof Function ? next(previous) : next;

    if (value) {
      byStorage(storage).setItem(key, JSON.stringify(value));
    } else {
      byStorage(storage).removeItem(key);
    }
    return value;
  };

export const useStorage = <V>(options: StorageProps<V>) =>
  useReducer(saveStorage(options), options.defaultValue, loadStorage(options));
