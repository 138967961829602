import { tz } from "@date-fns/tz";
import {
  addDays,
  addHours,
  addMonths,
  getDay,
  getHours,
  isBefore,
  set,
} from "date-fns";

import { dayOrder } from "@joy/shared-utils";

export type UpdateOptions = {
  now: Date;
  timezone?: string;
  schedule:
    | {
        days: {
          Monday: boolean;
          Tuesday: boolean;
          Wednesday: boolean;
          Thursday: boolean;
          Friday: boolean;
          Saturday: boolean;
          Sunday: boolean;
        };
        startTime: {
          hour: number;
          minute: number;
        };
        endTime?: number | null | undefined;
        frequency: number;
        every: "Week" | "Fortnight" | "Month";
      }
    | undefined
    | null;
};

const daysUntilEnabled = (
  date: Date,
  schedule: NonNullable<UpdateOptions["schedule"]>,
) => {
  const dayIdx = getDay(date);

  let days = -1;
  let day;
  while (!(day && schedule.days[day])) {
    days++;
    day = dayOrder[(dayIdx + days) % dayOrder.length];

    if (days > 7) {
      throw new Error("No days enabled in schedule");
    }
  }

  return days;
};

export const nextUpdate = ({
  now,
  schedule,
  timezone = "UTC",
}: UpdateOptions) => {
  if (!schedule) return undefined;

  if (!Object.values(schedule.days).some((enabled) => enabled))
    return undefined;
  if (schedule.startTime.hour >= (schedule.endTime || 24)) return undefined;

  const inTz = tz(timezone);
  let nextDate = set(
    now,
    {
      hours: schedule.startTime.hour,
      minutes: schedule.startTime.minute,
      seconds: 0,
      milliseconds: 0,
    },
    { in: inTz },
  );

  switch (schedule.every) {
    case "Month": {
      nextDate = set(nextDate, { date: 21 }, { in: inTz });
      if (isBefore(nextDate, now)) {
        nextDate = addMonths(nextDate, 1);
      }
      break;
    }
    case "Fortnight": {
      nextDate = set(nextDate, { date: 1 }, { in: inTz });
      if (isBefore(nextDate, now)) {
        nextDate = set(nextDate, { date: 14 }, { in: inTz });
      }
      if (isBefore(nextDate, now)) {
        nextDate = set(nextDate, { date: 1 }, { in: inTz });
        nextDate = addMonths(nextDate, 1);
      }
      break;
    }
    case "Week": {
      nextDate = addDays(nextDate, daysUntilEnabled(nextDate, schedule));
      let iterations = 0;
      while (isBefore(nextDate, now)) {
        const hour = getHours(nextDate) + schedule.frequency;
        if ((schedule.endTime && hour >= schedule.endTime) || hour >= 24) {
          nextDate = addDays(nextDate, 1);
          nextDate = set(
            nextDate,
            {
              hours: schedule.startTime.hour,
              minutes: schedule.startTime.minute,
            },
            { in: inTz },
          );
          nextDate = addDays(nextDate, daysUntilEnabled(nextDate, schedule));
        } else {
          nextDate = addHours(nextDate, schedule.frequency);
        }
        iterations++;

        if (iterations > 168) throw new Error("Could not find next update");
      }
      break;
    }
  }

  return new Date(nextDate);
};
