import { useMemo } from "react";
import { ZodType } from "zod";

export const useValidators = <V extends Record<string, ZodType>>(
  validation: V,
  submissionAttempts: number,
) =>
  useMemo(() => {
    const entries = Object.entries(validation).map(([key, value]) => [
      key,
      {
        onChange: submissionAttempts ? value : undefined,
        onSubmit: value,
      },
    ]);

    return Object.fromEntries(entries) as {
      [k in keyof V]: { onChange: V[k] | undefined; onSubmit: V[k] };
    };
  }, [!!submissionAttempts]);
