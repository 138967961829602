import {
  IconBasket,
  IconGauge,
  IconHistory,
  IconSquareRoundedPlus,
  IconTrashX,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";

import {
  Entity,
  EntityCreated,
  EntityUpdated,
  LinkButton,
  pageParts,
} from "../../components";
import { account, customer, gaugeQuery, tank, useAuth } from "../../data";
import { CommentsPanel } from "../comments";

export const GaugeInfoPanel = ({ gaugeId }: { gaugeId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(gaugeQuery(gaugeId));

  return (
    <div className={pageParts.page}>
      <Entity
        title="Gauge Info"
        edit={
          data.permissions.includes("install")
            ? {
                to: "/gauges/$gaugeId",
                params: { gaugeId },
                search: { action: "edit" },
                replace: true,
              }
            : undefined
        }
        item={data}
        details={[
          [
            [IconGauge, "Name", (c) => c.name],
            [IconBasket, "Model", (c) => c.detail.model],
            [
              tank.icon,
              "Tank",
              (c) =>
                c.tank && (
                  <LinkButton
                    kind="link"
                    variant="standard"
                    to="/tanks/$tankId"
                    params={{ tankId: c.tank.id }}
                    text={c.tank.name}
                  />
                ),
            ],
            [
              account.icon,
              "Account",
              (c) =>
                c.account && (
                  <LinkButton
                    kind="link"
                    variant="standard"
                    to="/accounts/$accountId"
                    params={{ accountId: c.account.id }}
                    text={c.account.name}
                  />
                ),
            ],
            hasTeamPermission("admin")
              ? [
                  customer.icon,
                  "Customer",
                  (c) =>
                    c.customer && (
                      <LinkButton
                        kind="link"
                        variant="standard"
                        to="/customers/$customerId"
                        params={{ customerId: c.customer.id }}
                        text={c.customer.name}
                      />
                    ),
                ]
              : undefined,
          ],
          [
            [IconSquareRoundedPlus, "Created at", EntityCreated],
            [IconHistory, "Updated", EntityUpdated],
          ],
        ]}
        actions={[
          hasTeamPermission("admin")
            ? {
                kind: "link",
                variant: "danger",
                search: { action: "delete" },
                replace: true,
                icon: IconTrashX,
                text: "Delete Gauge",
              }
            : undefined,
        ]}
      />
      {hasTeamPermission("admin") && (
        <CommentsPanel
          entityType="gauge"
          entityId={gaugeId}
          onDelete={{ to: "/gauges/$gaugeId", params: { gaugeId } }}
        />
      )}
    </div>
  );
};
