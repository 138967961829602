import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import { account, useAuth, usePrefetchRole, userRolesQuery } from "../../data";
import { useTable } from "../../hooks";
import { roleColumns } from "../role";

export const UserAccountsPanel = ({ userId }: { userId: string }) => {
  const { hasTeamPermission } = useAuth();
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const roles = useTable(userRolesQuery(userId, "account"), {
    columnDefs: roleColumns({
      prefetchRole,
      hasTeamPermission,
      navigate,
      display: "account",
      current: { to: "/users/$userId" },
      permissions: ["read", "write", "install", "admin"],
    }),
    create: {
      to: "/users/$userId",
      search: { action: "add", entity: "account" },
      replace: true,
    },
    word: account,
  });

  return <TablePage {...roles} />;
};
