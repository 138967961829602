import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { CustomerCreateContent } from "./create";

export const CustomersActionPanel = () => {
  const { Slide } = useMatch({
    from: "/_admin/customers/",
    select: (s) => {
      switch (s.search.action) {
        case "create":
          return { Slide: CustomerCreateContent };
      }

      return {};
    },
  });

  return <Actions slide={Slide && <Slide />} />;
};
