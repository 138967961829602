import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import {
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  stackCell,
} from "../../../components";
import {
  customer,
  customersQuery,
  listLoader,
  useAuth,
  userName,
} from "../../../data";
import { useTable } from "../../../hooks";
import { CustomersActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["create"]).optional(),
});

export const Route = createFileRoute("/_admin/customers/")({
  validateSearch,
  loader: async ({ context }) =>
    listLoader(context.queryClient, customersQuery()),
  component: Component,
});

export function Component() {
  const { hasTeamPermission } = useAuth();
  const customers = useTable(customersQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: () => [],
        }),
      ),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(actionCell()),
    ],
    select: (customerId) => ({
      to: "/customers/$customerId",
      params: { customerId },
    }),
    create: hasTeamPermission("super")
      ? { to: "/customers", search: { action: "create" }, replace: true }
      : undefined,
    word: customer,
  });

  return (
    <TablePage {...customers}>
      <StatsHeader stats={customers.stats} />
      <CustomersActionPanel />
    </TablePage>
  );
}
