import { SlideContent, TextField } from "../../components";
import { ColourField } from "../../components/forms/colour";
import { useCreateProduct } from "../../data";

export const ProductCreateContent = ({
  customerId,
}: {
  customerId?: string;
}) => {
  const { error, form, validators } = useCreateProduct(customerId);

  return (
    <SlideContent
      title="Add product"
      description="Get started by filling in the information below to create your new product."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Creating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Create", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="label"
        validators={validators.label}
        children={(field) => (
          <TextField field={field} label="Label" autoFocus />
        )}
      />
      <form.Field
        name="colour"
        validators={validators.colour}
        children={(field) => <ColourField field={field} label="Colour" />}
      />
    </SlideContent>
  );
};
