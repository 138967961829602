import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { sortField } from "@joy/shared-utils";

import {
  AddressField,
  ComboField,
  ListField,
  SlideContent,
  TextField,
} from "../../components";
import {
  productsQuery,
  tankUnitOptions,
  useAuth,
  useUpdateTank,
} from "../../data";

export const TankEditContent = ({ tankId }: { tankId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data, error, form, validators } = useUpdateTank(tankId);
  const globalProducts = useInfiniteQuery(productsQuery());
  const customerProducts = useInfiniteQuery(productsQuery(data.customerId));
  const products = useMemo(
    () =>
      sortField(
        [...(globalProducts.data || []), ...(customerProducts.data || [])],
        { dir: "asc", key: "label" },
      ),
    [globalProducts.data, customerProducts.data],
  );

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the tank information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Tank name" autoFocus />
        )}
      />
      {hasTeamPermission("admin") && (
        <form.Field
          name="activatedAt"
          children={(field) => (
            <TextField field={field} label="Activated At" type="date" />
          )}
        />
      )}
      <form.Field
        name="unit"
        validators={validators.unit}
        children={(field) => (
          <ListField
            field={field}
            label="Volume unit"
            options={
              validators.unit.onSubmit._def.innerType._def.innerType.options
            }
            optionLabel={(o) => (o ? tankUnitOptions[o] : "")}
          />
        )}
      />
      <form.Field
        name="product"
        children={(field) => (
          <ComboField
            field={field}
            label="Product"
            options={products}
            accessors={[(o) => o.label]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.label}
            immediate
          />
        )}
      />
      <form.Field
        name="location"
        validators={validators.location}
        children={(field) => <AddressField field={field} label="Location" />}
      />
    </SlideContent>
  );
};
