import { IconTrashX } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import clsx from "clsx";

import { technologyIcons } from "../../assets";
import {
  Button,
  CellImage,
  CellStack,
  ComboInput,
  Field,
  Label,
  cellVariants,
  tableParts,
} from "../../components";
import {
  AccountConfigContentProps,
  GaugesQuery,
  gaugesQuery,
} from "../../data";

export const JoyGaugeContent = ({ data, form }: AccountConfigContentProps) => {
  const gauges = useInfiniteQuery(gaugesQuery());

  return (
    <form.Field
      name="gauges"
      mode="array"
      children={(list) => (
        <>
          <table className={tableParts.table}>
            <tbody>
              {list.state.value.map((g, idx) => (
                <tr key={g.id}>
                  <td className="w-5" />
                  <td
                    className={clsx(
                      cellVariants.base,
                      cellVariants.padded,
                      "w-14",
                    )}
                  >
                    <CellImage
                      className="mx-auto"
                      src={technologyIcons[g.technology]}
                      alt={g.technology}
                    />
                  </td>
                  <td className={clsx(cellVariants.base, cellVariants.padded)}>
                    <CellStack
                      value={g.name}
                      detail={[[g.technology, g.model]]}
                    />
                  </td>
                  <td
                    className={clsx(
                      cellVariants.base,
                      cellVariants.padded,
                      "w-0",
                    )}
                  >
                    <Button
                      icon={IconTrashX}
                      kind="menu"
                      variant="danger"
                      onClick={() => list.removeValue(idx)}
                    />
                  </td>
                  <td className="w-5" />
                </tr>
              ))}
            </tbody>
          </table>
          <Field>
            <Label>Select gauge to add:</Label>
            <ComboInput
              options={(gauges.data || []).filter(
                (g) =>
                  (!g.account || g.account.id === data.id) &&
                  (!g.customer || g.customer.id === data.customer?.id) &&
                  !list.state.value.find((v) => v.id === g.id),
              )}
              empty="No unassigned gauges available"
              multiple={false}
              accessors={[(g) => g.name]}
              optionKey={(g) => g.id}
              optionLabel={(g) => g.name}
              value={null as GaugesQuery["gauges"]["gauges"][number] | null}
              onChange={(value) => {
                if (value) list.pushValue(value);
              }}
              immediate
            />
          </Field>
        </>
      )}
    />
  );
};
