import { ReactNode } from "react";

import { specialChars } from "../assets";
import { Box } from "./box";
import { LinkButton, LinkButtonProps } from "./button";

export type EntityPropDetail<I> =
  | [
      icon: (props: { className: string }) => ReactNode,
      key: string,
      value: (item: I) => ReactNode,
      show?: (item: I) => boolean,
    ]
  | undefined;

export type EntityProps<
  I,
  TTo extends string = "",
  E extends LinkButtonProps<TTo> = LinkButtonProps<TTo>,
  A extends (LinkButtonProps<TTo> | undefined)[] = (
    | LinkButtonProps<TTo>
    | undefined
  )[],
> = {
  title: string;
  edit?: E;
  item: I;
  details: EntityPropDetail<I>[][];
  actions?: A;
};

export const Entity = <
  I extends object,
  TTo extends string,
  E extends LinkButtonProps<TTo>,
  A extends (LinkButtonProps<TTo> | undefined)[],
>({
  title,
  edit,
  item,
  details,
  actions,
}: EntityProps<I, TTo, E, A>) => (
  <Box
    variant="info"
    header={{ title, edit: edit && { ...edit, kind: "link", text: "Edit" } }}
  >
    <div className="min-w-0 divide-y divide-gray-200">
      {details.map((group, i) => (
        <div
          key={`group-${i}`}
          className="flex min-w-0 flex-col gap-4 px-2 py-3"
        >
          {group
            .filter((i) => !!i)
            .map(
              ([Icon, label, getter, show]) =>
                (!show || show(item)) && (
                  <div
                    key={label}
                    className="flex w-full min-w-0 items-start gap-2 text-sm"
                  >
                    <Icon className="size-5 flex-none opacity-60" />
                    <span className="sr-only">{label}</span>
                    <span className="self-center truncate">
                      {getter(item) || specialChars.endash}
                    </span>
                  </div>
                ),
            )}
        </div>
      ))}
      {actions?.filter((a) => !!a).length ? (
        <div className="flex flex-col gap-4 px-2.5 py-4 text-sm">
          {actions
            .filter((a) => !!a)
            .map((a) => (
              <LinkButton key={a.text} {...a} />
            ))}
        </div>
      ) : undefined}
    </div>
  </Box>
);
