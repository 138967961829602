import { z } from "zod";

export const subscriptionSchema = z.discriminatedUnion("type", [
  z.object({ type: z.literal("ka") }),
  z.object({
    type: z.literal("entity"),
    action: z.enum(["INSERT", "MODIFY", "REMOVE"]).optional(),
    updated: z.object({
      id: z.string(),
      type: z.string(),
      updatedAt: z.coerce.date(),
    }),
    parent: z.object({
      id: z.string(),
      type: z.string(),
    }),
    sibling: z
      .object({
        id: z.string(),
        type: z.string(),
      })
      .optional(),
  }),
]);

export type SubscriptionResponse = z.infer<typeof subscriptionSchema>;
