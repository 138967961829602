import { createFileRoute } from "@tanstack/react-router";

import { Document } from "../../components";
import { loadDocument } from "../../data";

export const Route = createFileRoute("/_unauthenticated/privacy")({
  loader: async () => loadDocument("privacy"),
  head: () => ({ meta: [{ title: "Privacy Policy" }] }),
  component: Component,
});

export function Component() {
  const data = Route.useLoaderData();
  return <Document {...data} />;
}
