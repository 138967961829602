import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { DocumentDeleteContent } from "./delete";

export const DocumentActionPanel = () => {
  const { Modal, props } = useMatch({
    from: "/_admin/library/documents/$documentId",
    select: (s) => {
      const props = {
        documentId: s.params.documentId,
      };

      switch (s.search.action) {
        case "delete":
          return {
            Modal: DocumentDeleteContent,
            props,
          };
      }

      return { props };
    },
  });

  return <Actions modal={Modal && <Modal {...props} />} />;
};
