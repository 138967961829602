import { StrapTable, strappingCalculate } from "./strapping.js";

export type CippusOptions = {
  config: {
    specificGravityActual: number;
    specificGravityProbe: number;
    offsetHeight: number;
    offsetVolume: number;
  };
  strappingTable: StrapTable;
};

export const cippusHeight = (
  reading: number,
  {
    config: { specificGravityActual, specificGravityProbe, offsetHeight },
  }: CippusOptions,
) =>
  reading * 1000 * (specificGravityProbe / specificGravityActual) +
  offsetHeight;

export const cippusVolume = (reading: number, options: CippusOptions) => {
  const volume = strappingCalculate(
    options.strappingTable,
    cippusHeight(reading, options),
    "mm",
  );

  return volume ? volume + options.config.offsetVolume : undefined;
};
