import { ErrorComponentProps, useNavigate } from "@tanstack/react-router";
import clsx from "clsx";

import { useDisplayError } from "../data";
import { Button } from "./button";
import { scheme } from "./scheme";

export type ErrorProps = {
  code: string;
  heading: string;
  description: string;
  action: { onClick: () => void; label: string };
};

export const Error = ({ code, heading, description, action }: ErrorProps) => (
  <div className="flex size-full flex-col items-center justify-center">
    <div className="flex-1" />
    <div className="flex max-w-prose flex-none flex-col items-start gap-6 text-balance border-l-4 py-10 pl-6">
      <p
        className={clsx(
          "text-7xl font-extrabold tracking-tight lg:text-9xl",
          scheme.brand,
        )}
      >
        {code}
      </p>
      <p className={clsx("text-3xl font-bold md:text-4xl", scheme.standard)}>
        {heading}
      </p>
      <p className={clsx("text-lg font-light", scheme.dim)}>{description}</p>
      <Button variant="brand" onClick={action.onClick} text={action.label} />
    </div>
    <div className="flex-[3]" />
  </div>
);

export const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const navigate = useNavigate();
  const { code, message } = useDisplayError(error);

  return (
    <Error
      code={`${code || ""}`}
      heading={`${message || ""}.`}
      description="Sorry, something went wrong. Head back to the home page or refresh to try again."
      action={{
        onClick: () => navigate({ to: "/" }),
        label: "Go to home page",
      }}
    />
  );
};
