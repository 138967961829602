import { convertRange } from "./range.js";

export type StrapLine = { mm: number; l: number };

export type StrapTable = StrapLine[];

export const opposite: Record<keyof StrapLine, keyof StrapLine> = {
  mm: "l",
  l: "mm",
};

export const strappingMax = (table: StrapTable, key: keyof StrapLine) =>
  table.length ? Math.max(...table.map((line) => line[key])) : undefined;

export const strappingSort = (table: StrapTable, key: keyof StrapLine = "mm") =>
  table.slice(0).sort((a, b) => a[key] - b[key]);

export const strappingCalculate = (
  table: StrapTable,
  input: number,
  inputKey: keyof StrapLine = "mm",
) => {
  const sorted = strappingSort(table, inputKey);
  const index = sorted.findIndex((line) => line[inputKey] >= input);

  let below = sorted[index - 1];
  let above = sorted[index];
  if (index < 0) {
    below = sorted[sorted.length - 2];
    above = sorted[sorted.length - 1];
  } else if (index === 0) {
    below = sorted[0];
    above = sorted[1];
  }

  if (!below || !above) return undefined;

  return convertRange(input, {
    rangeIn: { min: below[inputKey], max: above[inputKey] },
    rangeOut: {
      min: below[opposite[inputKey]],
      max: above[opposite[inputKey]],
    },
  });
};
