export const convertPrice = (amount: string | null | undefined) =>
  typeof amount !== "string" ? undefined : Math.floor(parseFloat(amount) * 100);

export const unconvertPrice = (amount: number | null | undefined) =>
  typeof amount !== "number" ? "" : (amount / 100).toFixed(2);

export const clampValue = (
  value: number | null | undefined,
  [min, max]: [number, number],
) =>
  typeof value !== "number" ? undefined : Math.min(Math.max(value, min), max);

export const warningIndex = (
  percent: number | undefined,
  warnings: number[] | null | undefined,
) =>
  percent === undefined
    ? undefined
    : 4 -
      [-1, warnings?.[0] || 0, warnings?.[1] || 0, 101].findIndex(
        (w) => percent <= w,
      );
