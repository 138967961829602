import { tekADCProbeNames, tekADCProbes } from "@joy/shared-calculator";

import { specialChars } from "../../assets";
import {
  Field,
  Label,
  ListField,
  TextField,
  TextInputField,
  fieldParts,
} from "../../components";
import { GaugeConfigContentProps } from "../../data";

const ranges = ["Digital", "Analog", "Probe", "Height"] as const;

export const TekADCContent = ({
  form,
  validators,
}: GaugeConfigContentProps) => (
  <>
    <form.Field
      name="probeName"
      validators={{
        onChange: ({ value, fieldApi }) => {
          const probe = tekADCProbes.find((p) => p.name === value);
          if (probe) {
            for (const type of ranges) {
              fieldApi.form.setFieldValue(
                `range${type}.min`,
                probe[`range${type}`].min.toString(),
              );
              fieldApi.form.setFieldValue(
                `range${type}.max`,
                probe[`range${type}`].max.toString(),
              );
            }
          }

          return undefined;
        },
      }}
      children={(field) => (
        <ListField
          field={field}
          label="Probe Type"
          options={tekADCProbeNames}
        />
      )}
    />
    <form.Subscribe
      selector={(v) => v.values.probeName}
      children={(probeName) =>
        probeName === "Custom" ? (
          <div className={fieldParts.group}>
            {ranges.map((type) => (
              <Field key={type} kind="sub">
                <Label htmlFor={`range${type}.min`}>{type} Range </Label>
                <div className={fieldParts.row}>
                  <form.Field
                    name={`range${type}.min`}
                    validators={validators.range}
                    children={(field) => (
                      <TextInputField
                        field={field}
                        placeholder="Minimum"
                        inputMode="decimal"
                        addon="MIN"
                      />
                    )}
                  />
                  <div> {specialChars.endash} </div>
                  <form.Field
                    name={`range${type}.max`}
                    validators={validators.range}
                    children={(field) => (
                      <TextInputField
                        field={field}
                        placeholder="Maximum"
                        inputMode="decimal"
                        addon="MAX"
                      />
                    )}
                  />
                </div>
              </Field>
            ))}
          </div>
        ) : null
      }
    />

    <Field>
      <Label htmlFor="specificGravityProbe">Specific Gravity</Label>
      <div className={fieldParts.row}>
        <form.Field
          name="specificGravityProbe"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Probe"
              inputMode="decimal"
              addon="PROBE"
            />
          )}
        />
        <div> : </div>
        <form.Field
          name="specificGravityActual"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Actual"
              inputMode="decimal"
              addon="PRODUCT"
            />
          )}
        />
      </div>
    </Field>
    <form.Field
      name="offsetHeight"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Pressure Offset" inputMode="decimal" />
      )}
    />
    <form.Field
      name="offsetVolume"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Volume Offset" inputMode="decimal" />
      )}
    />
  </>
);
