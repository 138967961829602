import { useMemo } from "react";

import { Actions } from "../../components";
import { useGlobalPanel } from "../../hooks";
import { FeedbackContent } from "./feedback";
import { ProfileContent } from "./profile";

export const GlobalPanel = () => {
  const { panel, setPanel } = useGlobalPanel();

  const { Modal, Slide, props } = useMemo(() => {
    const props = {};

    switch (panel) {
      case "feedback":
        return { Modal: FeedbackContent, props };
      case "profile":
        return { Slide: ProfileContent, props };
    }

    return { props };
  }, [panel]);

  return (
    <Actions
      modal={Modal && <Modal {...props} />}
      slide={Slide && <Slide {...props} />}
      onClose={() => setPanel(undefined)}
    />
  );
};
