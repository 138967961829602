/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./../routes/__root";
import { Route as SuperRouteImport } from "./../routes/_super/route";
import { Route as GodRouteImport } from "./../routes/_god/route";
import { Route as AuthenticatedRouteImport } from "./../routes/_authenticated/route";
import { Route as AdminRouteImport } from "./../routes/_admin/route";
import { Route as IndexImport } from "./../routes/index";
import { Route as UnauthenticatedTermsImport } from "./../routes/_unauthenticated/terms";
import { Route as UnauthenticatedPrivacyImport } from "./../routes/_unauthenticated/privacy";
import { Route as UnauthenticatedLoginRouteImport } from "./../routes/_unauthenticated/login/route";
import { Route as SuperInvoicesRouteImport } from "./../routes/_super/invoices/route";
import { Route as AuthenticatedTanksRouteImport } from "./../routes/_authenticated/tanks/route";
import { Route as AuthenticatedGaugesRouteImport } from "./../routes/_authenticated/gauges/route";
import { Route as AuthenticatedAccountsRouteImport } from "./../routes/_authenticated/accounts/route";
import { Route as AdminUsersRouteImport } from "./../routes/_admin/users/route";
import { Route as AdminLibraryRouteImport } from "./../routes/_admin/library/route";
import { Route as AdminCustomersRouteImport } from "./../routes/_admin/customers/route";
import { Route as UnauthenticatedLoginIndexImport } from "./../routes/_unauthenticated/login/index";
import { Route as SuperInvoicesIndexImport } from "./../routes/_super/invoices/index";
import { Route as GodDevIndexImport } from "./../routes/_god/dev/index";
import { Route as AuthenticatedTanksIndexImport } from "./../routes/_authenticated/tanks/index";
import { Route as AuthenticatedGaugesIndexImport } from "./../routes/_authenticated/gauges/index";
import { Route as AuthenticatedAccountsIndexImport } from "./../routes/_authenticated/accounts/index";
import { Route as AdminUsersIndexImport } from "./../routes/_admin/users/index";
import { Route as AdminLibraryIndexImport } from "./../routes/_admin/library/index";
import { Route as AdminCustomersIndexImport } from "./../routes/_admin/customers/index";
import { Route as UnauthenticatedLoginRegisterImport } from "./../routes/_unauthenticated/login/register";
import { Route as UnauthenticatedLoginForgotImport } from "./../routes/_unauthenticated/login/forgot";
import { Route as SuperInvoicesInvoiceIdImport } from "./../routes/_super/invoices/$invoiceId";
import { Route as GodDevLogsImport } from "./../routes/_god/dev/logs";
import { Route as AuthenticatedTanksTankIdImport } from "./../routes/_authenticated/tanks/$tankId";
import { Route as AuthenticatedGaugesGaugeIdImport } from "./../routes/_authenticated/gauges/$gaugeId";
import { Route as AuthenticatedAccountsAccountIdImport } from "./../routes/_authenticated/accounts/$accountId";
import { Route as AdminUsersUserIdImport } from "./../routes/_admin/users/$userId";
import { Route as AdminCustomersCustomerIdImport } from "./../routes/_admin/customers/$customerId";
import { Route as AdminLibraryProductsRouteImport } from "./../routes/_admin/library/products/route";
import { Route as AdminLibraryPlansRouteImport } from "./../routes/_admin/library/plans/route";
import { Route as AdminLibraryDocumentsRouteImport } from "./../routes/_admin/library/documents/route";
import { Route as AdminLibraryProductsIndexImport } from "./../routes/_admin/library/products/index";
import { Route as AdminLibraryPlansIndexImport } from "./../routes/_admin/library/plans/index";
import { Route as AdminLibraryDocumentsIndexImport } from "./../routes/_admin/library/documents/index";
import { Route as AdminLibraryPlansPlanIdImport } from "./../routes/_admin/library/plans/$planId";
import { Route as AdminLibraryDocumentsDocumentIdImport } from "./../routes/_admin/library/documents/$documentId";

// Create/Update Routes

const SuperRouteRoute = SuperRouteImport.update({
  id: "/_super",
  getParentRoute: () => rootRoute,
} as any);

const GodRouteRoute = GodRouteImport.update({
  id: "/_god",
  getParentRoute: () => rootRoute,
} as any);

const AuthenticatedRouteRoute = AuthenticatedRouteImport.update({
  id: "/_authenticated",
  getParentRoute: () => rootRoute,
} as any);

const AdminRouteRoute = AdminRouteImport.update({
  id: "/_admin",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const UnauthenticatedTermsRoute = UnauthenticatedTermsImport.update({
  id: "/_unauthenticated/terms",
  path: "/terms",
  getParentRoute: () => rootRoute,
} as any);

const UnauthenticatedPrivacyRoute = UnauthenticatedPrivacyImport.update({
  id: "/_unauthenticated/privacy",
  path: "/privacy",
  getParentRoute: () => rootRoute,
} as any);

const UnauthenticatedLoginRouteRoute = UnauthenticatedLoginRouteImport.update({
  id: "/_unauthenticated/login",
  path: "/login",
  getParentRoute: () => rootRoute,
} as any);

const SuperInvoicesRouteRoute = SuperInvoicesRouteImport.update({
  id: "/invoices",
  path: "/invoices",
  getParentRoute: () => SuperRouteRoute,
} as any);

const AuthenticatedTanksRouteRoute = AuthenticatedTanksRouteImport.update({
  id: "/tanks",
  path: "/tanks",
  getParentRoute: () => AuthenticatedRouteRoute,
} as any);

const AuthenticatedGaugesRouteRoute = AuthenticatedGaugesRouteImport.update({
  id: "/gauges",
  path: "/gauges",
  getParentRoute: () => AuthenticatedRouteRoute,
} as any);

const AuthenticatedAccountsRouteRoute = AuthenticatedAccountsRouteImport.update(
  {
    id: "/accounts",
    path: "/accounts",
    getParentRoute: () => AuthenticatedRouteRoute,
  } as any,
);

const AdminUsersRouteRoute = AdminUsersRouteImport.update({
  id: "/users",
  path: "/users",
  getParentRoute: () => AdminRouteRoute,
} as any);

const AdminLibraryRouteRoute = AdminLibraryRouteImport.update({
  id: "/library",
  path: "/library",
  getParentRoute: () => AdminRouteRoute,
} as any);

const AdminCustomersRouteRoute = AdminCustomersRouteImport.update({
  id: "/customers",
  path: "/customers",
  getParentRoute: () => AdminRouteRoute,
} as any);

const UnauthenticatedLoginIndexRoute = UnauthenticatedLoginIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => UnauthenticatedLoginRouteRoute,
} as any);

const SuperInvoicesIndexRoute = SuperInvoicesIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => SuperInvoicesRouteRoute,
} as any);

const GodDevIndexRoute = GodDevIndexImport.update({
  id: "/dev/",
  path: "/dev/",
  getParentRoute: () => GodRouteRoute,
} as any);

const AuthenticatedTanksIndexRoute = AuthenticatedTanksIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthenticatedTanksRouteRoute,
} as any);

const AuthenticatedGaugesIndexRoute = AuthenticatedGaugesIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthenticatedGaugesRouteRoute,
} as any);

const AuthenticatedAccountsIndexRoute = AuthenticatedAccountsIndexImport.update(
  {
    id: "/",
    path: "/",
    getParentRoute: () => AuthenticatedAccountsRouteRoute,
  } as any,
);

const AdminUsersIndexRoute = AdminUsersIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminUsersRouteRoute,
} as any);

const AdminLibraryIndexRoute = AdminLibraryIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminLibraryRouteRoute,
} as any);

const AdminCustomersIndexRoute = AdminCustomersIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminCustomersRouteRoute,
} as any);

const UnauthenticatedLoginRegisterRoute =
  UnauthenticatedLoginRegisterImport.update({
    id: "/register",
    path: "/register",
    getParentRoute: () => UnauthenticatedLoginRouteRoute,
  } as any);

const UnauthenticatedLoginForgotRoute = UnauthenticatedLoginForgotImport.update(
  {
    id: "/forgot",
    path: "/forgot",
    getParentRoute: () => UnauthenticatedLoginRouteRoute,
  } as any,
);

const SuperInvoicesInvoiceIdRoute = SuperInvoicesInvoiceIdImport.update({
  id: "/$invoiceId",
  path: "/$invoiceId",
  getParentRoute: () => SuperInvoicesRouteRoute,
} as any);

const GodDevLogsRoute = GodDevLogsImport.update({
  id: "/dev/logs",
  path: "/dev/logs",
  getParentRoute: () => GodRouteRoute,
} as any);

const AuthenticatedTanksTankIdRoute = AuthenticatedTanksTankIdImport.update({
  id: "/$tankId",
  path: "/$tankId",
  getParentRoute: () => AuthenticatedTanksRouteRoute,
} as any);

const AuthenticatedGaugesGaugeIdRoute = AuthenticatedGaugesGaugeIdImport.update(
  {
    id: "/$gaugeId",
    path: "/$gaugeId",
    getParentRoute: () => AuthenticatedGaugesRouteRoute,
  } as any,
);

const AuthenticatedAccountsAccountIdRoute =
  AuthenticatedAccountsAccountIdImport.update({
    id: "/$accountId",
    path: "/$accountId",
    getParentRoute: () => AuthenticatedAccountsRouteRoute,
  } as any);

const AdminUsersUserIdRoute = AdminUsersUserIdImport.update({
  id: "/$userId",
  path: "/$userId",
  getParentRoute: () => AdminUsersRouteRoute,
} as any);

const AdminCustomersCustomerIdRoute = AdminCustomersCustomerIdImport.update({
  id: "/$customerId",
  path: "/$customerId",
  getParentRoute: () => AdminCustomersRouteRoute,
} as any);

const AdminLibraryProductsRouteRoute = AdminLibraryProductsRouteImport.update({
  id: "/products",
  path: "/products",
  getParentRoute: () => AdminLibraryRouteRoute,
} as any);

const AdminLibraryPlansRouteRoute = AdminLibraryPlansRouteImport.update({
  id: "/plans",
  path: "/plans",
  getParentRoute: () => AdminLibraryRouteRoute,
} as any);

const AdminLibraryDocumentsRouteRoute = AdminLibraryDocumentsRouteImport.update(
  {
    id: "/documents",
    path: "/documents",
    getParentRoute: () => AdminLibraryRouteRoute,
  } as any,
);

const AdminLibraryProductsIndexRoute = AdminLibraryProductsIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminLibraryProductsRouteRoute,
} as any);

const AdminLibraryPlansIndexRoute = AdminLibraryPlansIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminLibraryPlansRouteRoute,
} as any);

const AdminLibraryDocumentsIndexRoute = AdminLibraryDocumentsIndexImport.update(
  {
    id: "/",
    path: "/",
    getParentRoute: () => AdminLibraryDocumentsRouteRoute,
  } as any,
);

const AdminLibraryPlansPlanIdRoute = AdminLibraryPlansPlanIdImport.update({
  id: "/$planId",
  path: "/$planId",
  getParentRoute: () => AdminLibraryPlansRouteRoute,
} as any);

const AdminLibraryDocumentsDocumentIdRoute =
  AdminLibraryDocumentsDocumentIdImport.update({
    id: "/$documentId",
    path: "/$documentId",
    getParentRoute: () => AdminLibraryDocumentsRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin": {
      id: "/_admin";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AdminRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_authenticated": {
      id: "/_authenticated";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AuthenticatedRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_god": {
      id: "/_god";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof GodRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_super": {
      id: "/_super";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof SuperRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/customers": {
      id: "/_admin/customers";
      path: "/customers";
      fullPath: "/customers";
      preLoaderRoute: typeof AdminCustomersRouteImport;
      parentRoute: typeof AdminRouteImport;
    };
    "/_admin/library": {
      id: "/_admin/library";
      path: "/library";
      fullPath: "/library";
      preLoaderRoute: typeof AdminLibraryRouteImport;
      parentRoute: typeof AdminRouteImport;
    };
    "/_admin/users": {
      id: "/_admin/users";
      path: "/users";
      fullPath: "/users";
      preLoaderRoute: typeof AdminUsersRouteImport;
      parentRoute: typeof AdminRouteImport;
    };
    "/_authenticated/accounts": {
      id: "/_authenticated/accounts";
      path: "/accounts";
      fullPath: "/accounts";
      preLoaderRoute: typeof AuthenticatedAccountsRouteImport;
      parentRoute: typeof AuthenticatedRouteImport;
    };
    "/_authenticated/gauges": {
      id: "/_authenticated/gauges";
      path: "/gauges";
      fullPath: "/gauges";
      preLoaderRoute: typeof AuthenticatedGaugesRouteImport;
      parentRoute: typeof AuthenticatedRouteImport;
    };
    "/_authenticated/tanks": {
      id: "/_authenticated/tanks";
      path: "/tanks";
      fullPath: "/tanks";
      preLoaderRoute: typeof AuthenticatedTanksRouteImport;
      parentRoute: typeof AuthenticatedRouteImport;
    };
    "/_super/invoices": {
      id: "/_super/invoices";
      path: "/invoices";
      fullPath: "/invoices";
      preLoaderRoute: typeof SuperInvoicesRouteImport;
      parentRoute: typeof SuperRouteImport;
    };
    "/_unauthenticated/login": {
      id: "/_unauthenticated/login";
      path: "/login";
      fullPath: "/login";
      preLoaderRoute: typeof UnauthenticatedLoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_unauthenticated/privacy": {
      id: "/_unauthenticated/privacy";
      path: "/privacy";
      fullPath: "/privacy";
      preLoaderRoute: typeof UnauthenticatedPrivacyImport;
      parentRoute: typeof rootRoute;
    };
    "/_unauthenticated/terms": {
      id: "/_unauthenticated/terms";
      path: "/terms";
      fullPath: "/terms";
      preLoaderRoute: typeof UnauthenticatedTermsImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/library/documents": {
      id: "/_admin/library/documents";
      path: "/documents";
      fullPath: "/library/documents";
      preLoaderRoute: typeof AdminLibraryDocumentsRouteImport;
      parentRoute: typeof AdminLibraryRouteImport;
    };
    "/_admin/library/plans": {
      id: "/_admin/library/plans";
      path: "/plans";
      fullPath: "/library/plans";
      preLoaderRoute: typeof AdminLibraryPlansRouteImport;
      parentRoute: typeof AdminLibraryRouteImport;
    };
    "/_admin/library/products": {
      id: "/_admin/library/products";
      path: "/products";
      fullPath: "/library/products";
      preLoaderRoute: typeof AdminLibraryProductsRouteImport;
      parentRoute: typeof AdminLibraryRouteImport;
    };
    "/_admin/customers/$customerId": {
      id: "/_admin/customers/$customerId";
      path: "/$customerId";
      fullPath: "/customers/$customerId";
      preLoaderRoute: typeof AdminCustomersCustomerIdImport;
      parentRoute: typeof AdminCustomersRouteImport;
    };
    "/_admin/users/$userId": {
      id: "/_admin/users/$userId";
      path: "/$userId";
      fullPath: "/users/$userId";
      preLoaderRoute: typeof AdminUsersUserIdImport;
      parentRoute: typeof AdminUsersRouteImport;
    };
    "/_authenticated/accounts/$accountId": {
      id: "/_authenticated/accounts/$accountId";
      path: "/$accountId";
      fullPath: "/accounts/$accountId";
      preLoaderRoute: typeof AuthenticatedAccountsAccountIdImport;
      parentRoute: typeof AuthenticatedAccountsRouteImport;
    };
    "/_authenticated/gauges/$gaugeId": {
      id: "/_authenticated/gauges/$gaugeId";
      path: "/$gaugeId";
      fullPath: "/gauges/$gaugeId";
      preLoaderRoute: typeof AuthenticatedGaugesGaugeIdImport;
      parentRoute: typeof AuthenticatedGaugesRouteImport;
    };
    "/_authenticated/tanks/$tankId": {
      id: "/_authenticated/tanks/$tankId";
      path: "/$tankId";
      fullPath: "/tanks/$tankId";
      preLoaderRoute: typeof AuthenticatedTanksTankIdImport;
      parentRoute: typeof AuthenticatedTanksRouteImport;
    };
    "/_god/dev/logs": {
      id: "/_god/dev/logs";
      path: "/dev/logs";
      fullPath: "/dev/logs";
      preLoaderRoute: typeof GodDevLogsImport;
      parentRoute: typeof GodRouteImport;
    };
    "/_super/invoices/$invoiceId": {
      id: "/_super/invoices/$invoiceId";
      path: "/$invoiceId";
      fullPath: "/invoices/$invoiceId";
      preLoaderRoute: typeof SuperInvoicesInvoiceIdImport;
      parentRoute: typeof SuperInvoicesRouteImport;
    };
    "/_unauthenticated/login/forgot": {
      id: "/_unauthenticated/login/forgot";
      path: "/forgot";
      fullPath: "/login/forgot";
      preLoaderRoute: typeof UnauthenticatedLoginForgotImport;
      parentRoute: typeof UnauthenticatedLoginRouteImport;
    };
    "/_unauthenticated/login/register": {
      id: "/_unauthenticated/login/register";
      path: "/register";
      fullPath: "/login/register";
      preLoaderRoute: typeof UnauthenticatedLoginRegisterImport;
      parentRoute: typeof UnauthenticatedLoginRouteImport;
    };
    "/_admin/customers/": {
      id: "/_admin/customers/";
      path: "/";
      fullPath: "/customers/";
      preLoaderRoute: typeof AdminCustomersIndexImport;
      parentRoute: typeof AdminCustomersRouteImport;
    };
    "/_admin/library/": {
      id: "/_admin/library/";
      path: "/";
      fullPath: "/library/";
      preLoaderRoute: typeof AdminLibraryIndexImport;
      parentRoute: typeof AdminLibraryRouteImport;
    };
    "/_admin/users/": {
      id: "/_admin/users/";
      path: "/";
      fullPath: "/users/";
      preLoaderRoute: typeof AdminUsersIndexImport;
      parentRoute: typeof AdminUsersRouteImport;
    };
    "/_authenticated/accounts/": {
      id: "/_authenticated/accounts/";
      path: "/";
      fullPath: "/accounts/";
      preLoaderRoute: typeof AuthenticatedAccountsIndexImport;
      parentRoute: typeof AuthenticatedAccountsRouteImport;
    };
    "/_authenticated/gauges/": {
      id: "/_authenticated/gauges/";
      path: "/";
      fullPath: "/gauges/";
      preLoaderRoute: typeof AuthenticatedGaugesIndexImport;
      parentRoute: typeof AuthenticatedGaugesRouteImport;
    };
    "/_authenticated/tanks/": {
      id: "/_authenticated/tanks/";
      path: "/";
      fullPath: "/tanks/";
      preLoaderRoute: typeof AuthenticatedTanksIndexImport;
      parentRoute: typeof AuthenticatedTanksRouteImport;
    };
    "/_god/dev/": {
      id: "/_god/dev/";
      path: "/dev";
      fullPath: "/dev";
      preLoaderRoute: typeof GodDevIndexImport;
      parentRoute: typeof GodRouteImport;
    };
    "/_super/invoices/": {
      id: "/_super/invoices/";
      path: "/";
      fullPath: "/invoices/";
      preLoaderRoute: typeof SuperInvoicesIndexImport;
      parentRoute: typeof SuperInvoicesRouteImport;
    };
    "/_unauthenticated/login/": {
      id: "/_unauthenticated/login/";
      path: "/";
      fullPath: "/login/";
      preLoaderRoute: typeof UnauthenticatedLoginIndexImport;
      parentRoute: typeof UnauthenticatedLoginRouteImport;
    };
    "/_admin/library/documents/$documentId": {
      id: "/_admin/library/documents/$documentId";
      path: "/$documentId";
      fullPath: "/library/documents/$documentId";
      preLoaderRoute: typeof AdminLibraryDocumentsDocumentIdImport;
      parentRoute: typeof AdminLibraryDocumentsRouteImport;
    };
    "/_admin/library/plans/$planId": {
      id: "/_admin/library/plans/$planId";
      path: "/$planId";
      fullPath: "/library/plans/$planId";
      preLoaderRoute: typeof AdminLibraryPlansPlanIdImport;
      parentRoute: typeof AdminLibraryPlansRouteImport;
    };
    "/_admin/library/documents/": {
      id: "/_admin/library/documents/";
      path: "/";
      fullPath: "/library/documents/";
      preLoaderRoute: typeof AdminLibraryDocumentsIndexImport;
      parentRoute: typeof AdminLibraryDocumentsRouteImport;
    };
    "/_admin/library/plans/": {
      id: "/_admin/library/plans/";
      path: "/";
      fullPath: "/library/plans/";
      preLoaderRoute: typeof AdminLibraryPlansIndexImport;
      parentRoute: typeof AdminLibraryPlansRouteImport;
    };
    "/_admin/library/products/": {
      id: "/_admin/library/products/";
      path: "/";
      fullPath: "/library/products/";
      preLoaderRoute: typeof AdminLibraryProductsIndexImport;
      parentRoute: typeof AdminLibraryProductsRouteImport;
    };
  }
}

// Create and export the route tree

interface AdminCustomersRouteRouteChildren {
  AdminCustomersCustomerIdRoute: typeof AdminCustomersCustomerIdRoute;
  AdminCustomersIndexRoute: typeof AdminCustomersIndexRoute;
}

const AdminCustomersRouteRouteChildren: AdminCustomersRouteRouteChildren = {
  AdminCustomersCustomerIdRoute: AdminCustomersCustomerIdRoute,
  AdminCustomersIndexRoute: AdminCustomersIndexRoute,
};

const AdminCustomersRouteRouteWithChildren =
  AdminCustomersRouteRoute._addFileChildren(AdminCustomersRouteRouteChildren);

interface AdminLibraryDocumentsRouteRouteChildren {
  AdminLibraryDocumentsDocumentIdRoute: typeof AdminLibraryDocumentsDocumentIdRoute;
  AdminLibraryDocumentsIndexRoute: typeof AdminLibraryDocumentsIndexRoute;
}

const AdminLibraryDocumentsRouteRouteChildren: AdminLibraryDocumentsRouteRouteChildren =
  {
    AdminLibraryDocumentsDocumentIdRoute: AdminLibraryDocumentsDocumentIdRoute,
    AdminLibraryDocumentsIndexRoute: AdminLibraryDocumentsIndexRoute,
  };

const AdminLibraryDocumentsRouteRouteWithChildren =
  AdminLibraryDocumentsRouteRoute._addFileChildren(
    AdminLibraryDocumentsRouteRouteChildren,
  );

interface AdminLibraryPlansRouteRouteChildren {
  AdminLibraryPlansPlanIdRoute: typeof AdminLibraryPlansPlanIdRoute;
  AdminLibraryPlansIndexRoute: typeof AdminLibraryPlansIndexRoute;
}

const AdminLibraryPlansRouteRouteChildren: AdminLibraryPlansRouteRouteChildren =
  {
    AdminLibraryPlansPlanIdRoute: AdminLibraryPlansPlanIdRoute,
    AdminLibraryPlansIndexRoute: AdminLibraryPlansIndexRoute,
  };

const AdminLibraryPlansRouteRouteWithChildren =
  AdminLibraryPlansRouteRoute._addFileChildren(
    AdminLibraryPlansRouteRouteChildren,
  );

interface AdminLibraryProductsRouteRouteChildren {
  AdminLibraryProductsIndexRoute: typeof AdminLibraryProductsIndexRoute;
}

const AdminLibraryProductsRouteRouteChildren: AdminLibraryProductsRouteRouteChildren =
  {
    AdminLibraryProductsIndexRoute: AdminLibraryProductsIndexRoute,
  };

const AdminLibraryProductsRouteRouteWithChildren =
  AdminLibraryProductsRouteRoute._addFileChildren(
    AdminLibraryProductsRouteRouteChildren,
  );

interface AdminLibraryRouteRouteChildren {
  AdminLibraryDocumentsRouteRoute: typeof AdminLibraryDocumentsRouteRouteWithChildren;
  AdminLibraryPlansRouteRoute: typeof AdminLibraryPlansRouteRouteWithChildren;
  AdminLibraryProductsRouteRoute: typeof AdminLibraryProductsRouteRouteWithChildren;
  AdminLibraryIndexRoute: typeof AdminLibraryIndexRoute;
}

const AdminLibraryRouteRouteChildren: AdminLibraryRouteRouteChildren = {
  AdminLibraryDocumentsRouteRoute: AdminLibraryDocumentsRouteRouteWithChildren,
  AdminLibraryPlansRouteRoute: AdminLibraryPlansRouteRouteWithChildren,
  AdminLibraryProductsRouteRoute: AdminLibraryProductsRouteRouteWithChildren,
  AdminLibraryIndexRoute: AdminLibraryIndexRoute,
};

const AdminLibraryRouteRouteWithChildren =
  AdminLibraryRouteRoute._addFileChildren(AdminLibraryRouteRouteChildren);

interface AdminUsersRouteRouteChildren {
  AdminUsersUserIdRoute: typeof AdminUsersUserIdRoute;
  AdminUsersIndexRoute: typeof AdminUsersIndexRoute;
}

const AdminUsersRouteRouteChildren: AdminUsersRouteRouteChildren = {
  AdminUsersUserIdRoute: AdminUsersUserIdRoute,
  AdminUsersIndexRoute: AdminUsersIndexRoute,
};

const AdminUsersRouteRouteWithChildren = AdminUsersRouteRoute._addFileChildren(
  AdminUsersRouteRouteChildren,
);

interface AdminRouteRouteChildren {
  AdminCustomersRouteRoute: typeof AdminCustomersRouteRouteWithChildren;
  AdminLibraryRouteRoute: typeof AdminLibraryRouteRouteWithChildren;
  AdminUsersRouteRoute: typeof AdminUsersRouteRouteWithChildren;
}

const AdminRouteRouteChildren: AdminRouteRouteChildren = {
  AdminCustomersRouteRoute: AdminCustomersRouteRouteWithChildren,
  AdminLibraryRouteRoute: AdminLibraryRouteRouteWithChildren,
  AdminUsersRouteRoute: AdminUsersRouteRouteWithChildren,
};

const AdminRouteRouteWithChildren = AdminRouteRoute._addFileChildren(
  AdminRouteRouteChildren,
);

interface AuthenticatedAccountsRouteRouteChildren {
  AuthenticatedAccountsAccountIdRoute: typeof AuthenticatedAccountsAccountIdRoute;
  AuthenticatedAccountsIndexRoute: typeof AuthenticatedAccountsIndexRoute;
}

const AuthenticatedAccountsRouteRouteChildren: AuthenticatedAccountsRouteRouteChildren =
  {
    AuthenticatedAccountsAccountIdRoute: AuthenticatedAccountsAccountIdRoute,
    AuthenticatedAccountsIndexRoute: AuthenticatedAccountsIndexRoute,
  };

const AuthenticatedAccountsRouteRouteWithChildren =
  AuthenticatedAccountsRouteRoute._addFileChildren(
    AuthenticatedAccountsRouteRouteChildren,
  );

interface AuthenticatedGaugesRouteRouteChildren {
  AuthenticatedGaugesGaugeIdRoute: typeof AuthenticatedGaugesGaugeIdRoute;
  AuthenticatedGaugesIndexRoute: typeof AuthenticatedGaugesIndexRoute;
}

const AuthenticatedGaugesRouteRouteChildren: AuthenticatedGaugesRouteRouteChildren =
  {
    AuthenticatedGaugesGaugeIdRoute: AuthenticatedGaugesGaugeIdRoute,
    AuthenticatedGaugesIndexRoute: AuthenticatedGaugesIndexRoute,
  };

const AuthenticatedGaugesRouteRouteWithChildren =
  AuthenticatedGaugesRouteRoute._addFileChildren(
    AuthenticatedGaugesRouteRouteChildren,
  );

interface AuthenticatedTanksRouteRouteChildren {
  AuthenticatedTanksTankIdRoute: typeof AuthenticatedTanksTankIdRoute;
  AuthenticatedTanksIndexRoute: typeof AuthenticatedTanksIndexRoute;
}

const AuthenticatedTanksRouteRouteChildren: AuthenticatedTanksRouteRouteChildren =
  {
    AuthenticatedTanksTankIdRoute: AuthenticatedTanksTankIdRoute,
    AuthenticatedTanksIndexRoute: AuthenticatedTanksIndexRoute,
  };

const AuthenticatedTanksRouteRouteWithChildren =
  AuthenticatedTanksRouteRoute._addFileChildren(
    AuthenticatedTanksRouteRouteChildren,
  );

interface AuthenticatedRouteRouteChildren {
  AuthenticatedAccountsRouteRoute: typeof AuthenticatedAccountsRouteRouteWithChildren;
  AuthenticatedGaugesRouteRoute: typeof AuthenticatedGaugesRouteRouteWithChildren;
  AuthenticatedTanksRouteRoute: typeof AuthenticatedTanksRouteRouteWithChildren;
}

const AuthenticatedRouteRouteChildren: AuthenticatedRouteRouteChildren = {
  AuthenticatedAccountsRouteRoute: AuthenticatedAccountsRouteRouteWithChildren,
  AuthenticatedGaugesRouteRoute: AuthenticatedGaugesRouteRouteWithChildren,
  AuthenticatedTanksRouteRoute: AuthenticatedTanksRouteRouteWithChildren,
};

const AuthenticatedRouteRouteWithChildren =
  AuthenticatedRouteRoute._addFileChildren(AuthenticatedRouteRouteChildren);

interface GodRouteRouteChildren {
  GodDevLogsRoute: typeof GodDevLogsRoute;
  GodDevIndexRoute: typeof GodDevIndexRoute;
}

const GodRouteRouteChildren: GodRouteRouteChildren = {
  GodDevLogsRoute: GodDevLogsRoute,
  GodDevIndexRoute: GodDevIndexRoute,
};

const GodRouteRouteWithChildren = GodRouteRoute._addFileChildren(
  GodRouteRouteChildren,
);

interface SuperInvoicesRouteRouteChildren {
  SuperInvoicesInvoiceIdRoute: typeof SuperInvoicesInvoiceIdRoute;
  SuperInvoicesIndexRoute: typeof SuperInvoicesIndexRoute;
}

const SuperInvoicesRouteRouteChildren: SuperInvoicesRouteRouteChildren = {
  SuperInvoicesInvoiceIdRoute: SuperInvoicesInvoiceIdRoute,
  SuperInvoicesIndexRoute: SuperInvoicesIndexRoute,
};

const SuperInvoicesRouteRouteWithChildren =
  SuperInvoicesRouteRoute._addFileChildren(SuperInvoicesRouteRouteChildren);

interface SuperRouteRouteChildren {
  SuperInvoicesRouteRoute: typeof SuperInvoicesRouteRouteWithChildren;
}

const SuperRouteRouteChildren: SuperRouteRouteChildren = {
  SuperInvoicesRouteRoute: SuperInvoicesRouteRouteWithChildren,
};

const SuperRouteRouteWithChildren = SuperRouteRoute._addFileChildren(
  SuperRouteRouteChildren,
);

interface UnauthenticatedLoginRouteRouteChildren {
  UnauthenticatedLoginForgotRoute: typeof UnauthenticatedLoginForgotRoute;
  UnauthenticatedLoginRegisterRoute: typeof UnauthenticatedLoginRegisterRoute;
  UnauthenticatedLoginIndexRoute: typeof UnauthenticatedLoginIndexRoute;
}

const UnauthenticatedLoginRouteRouteChildren: UnauthenticatedLoginRouteRouteChildren =
  {
    UnauthenticatedLoginForgotRoute: UnauthenticatedLoginForgotRoute,
    UnauthenticatedLoginRegisterRoute: UnauthenticatedLoginRegisterRoute,
    UnauthenticatedLoginIndexRoute: UnauthenticatedLoginIndexRoute,
  };

const UnauthenticatedLoginRouteRouteWithChildren =
  UnauthenticatedLoginRouteRoute._addFileChildren(
    UnauthenticatedLoginRouteRouteChildren,
  );

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "": typeof SuperRouteRouteWithChildren;
  "/customers": typeof AdminCustomersRouteRouteWithChildren;
  "/library": typeof AdminLibraryRouteRouteWithChildren;
  "/users": typeof AdminUsersRouteRouteWithChildren;
  "/accounts": typeof AuthenticatedAccountsRouteRouteWithChildren;
  "/gauges": typeof AuthenticatedGaugesRouteRouteWithChildren;
  "/tanks": typeof AuthenticatedTanksRouteRouteWithChildren;
  "/invoices": typeof SuperInvoicesRouteRouteWithChildren;
  "/login": typeof UnauthenticatedLoginRouteRouteWithChildren;
  "/privacy": typeof UnauthenticatedPrivacyRoute;
  "/terms": typeof UnauthenticatedTermsRoute;
  "/library/documents": typeof AdminLibraryDocumentsRouteRouteWithChildren;
  "/library/plans": typeof AdminLibraryPlansRouteRouteWithChildren;
  "/library/products": typeof AdminLibraryProductsRouteRouteWithChildren;
  "/customers/$customerId": typeof AdminCustomersCustomerIdRoute;
  "/users/$userId": typeof AdminUsersUserIdRoute;
  "/accounts/$accountId": typeof AuthenticatedAccountsAccountIdRoute;
  "/gauges/$gaugeId": typeof AuthenticatedGaugesGaugeIdRoute;
  "/tanks/$tankId": typeof AuthenticatedTanksTankIdRoute;
  "/dev/logs": typeof GodDevLogsRoute;
  "/invoices/$invoiceId": typeof SuperInvoicesInvoiceIdRoute;
  "/login/forgot": typeof UnauthenticatedLoginForgotRoute;
  "/login/register": typeof UnauthenticatedLoginRegisterRoute;
  "/customers/": typeof AdminCustomersIndexRoute;
  "/library/": typeof AdminLibraryIndexRoute;
  "/users/": typeof AdminUsersIndexRoute;
  "/accounts/": typeof AuthenticatedAccountsIndexRoute;
  "/gauges/": typeof AuthenticatedGaugesIndexRoute;
  "/tanks/": typeof AuthenticatedTanksIndexRoute;
  "/dev": typeof GodDevIndexRoute;
  "/invoices/": typeof SuperInvoicesIndexRoute;
  "/login/": typeof UnauthenticatedLoginIndexRoute;
  "/library/documents/$documentId": typeof AdminLibraryDocumentsDocumentIdRoute;
  "/library/plans/$planId": typeof AdminLibraryPlansPlanIdRoute;
  "/library/documents/": typeof AdminLibraryDocumentsIndexRoute;
  "/library/plans/": typeof AdminLibraryPlansIndexRoute;
  "/library/products/": typeof AdminLibraryProductsIndexRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "": typeof SuperRouteRouteWithChildren;
  "/privacy": typeof UnauthenticatedPrivacyRoute;
  "/terms": typeof UnauthenticatedTermsRoute;
  "/customers/$customerId": typeof AdminCustomersCustomerIdRoute;
  "/users/$userId": typeof AdminUsersUserIdRoute;
  "/accounts/$accountId": typeof AuthenticatedAccountsAccountIdRoute;
  "/gauges/$gaugeId": typeof AuthenticatedGaugesGaugeIdRoute;
  "/tanks/$tankId": typeof AuthenticatedTanksTankIdRoute;
  "/dev/logs": typeof GodDevLogsRoute;
  "/invoices/$invoiceId": typeof SuperInvoicesInvoiceIdRoute;
  "/login/forgot": typeof UnauthenticatedLoginForgotRoute;
  "/login/register": typeof UnauthenticatedLoginRegisterRoute;
  "/customers": typeof AdminCustomersIndexRoute;
  "/library": typeof AdminLibraryIndexRoute;
  "/users": typeof AdminUsersIndexRoute;
  "/accounts": typeof AuthenticatedAccountsIndexRoute;
  "/gauges": typeof AuthenticatedGaugesIndexRoute;
  "/tanks": typeof AuthenticatedTanksIndexRoute;
  "/dev": typeof GodDevIndexRoute;
  "/invoices": typeof SuperInvoicesIndexRoute;
  "/login": typeof UnauthenticatedLoginIndexRoute;
  "/library/documents/$documentId": typeof AdminLibraryDocumentsDocumentIdRoute;
  "/library/plans/$planId": typeof AdminLibraryPlansPlanIdRoute;
  "/library/documents": typeof AdminLibraryDocumentsIndexRoute;
  "/library/plans": typeof AdminLibraryPlansIndexRoute;
  "/library/products": typeof AdminLibraryProductsIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/_admin": typeof AdminRouteRouteWithChildren;
  "/_authenticated": typeof AuthenticatedRouteRouteWithChildren;
  "/_god": typeof GodRouteRouteWithChildren;
  "/_super": typeof SuperRouteRouteWithChildren;
  "/_admin/customers": typeof AdminCustomersRouteRouteWithChildren;
  "/_admin/library": typeof AdminLibraryRouteRouteWithChildren;
  "/_admin/users": typeof AdminUsersRouteRouteWithChildren;
  "/_authenticated/accounts": typeof AuthenticatedAccountsRouteRouteWithChildren;
  "/_authenticated/gauges": typeof AuthenticatedGaugesRouteRouteWithChildren;
  "/_authenticated/tanks": typeof AuthenticatedTanksRouteRouteWithChildren;
  "/_super/invoices": typeof SuperInvoicesRouteRouteWithChildren;
  "/_unauthenticated/login": typeof UnauthenticatedLoginRouteRouteWithChildren;
  "/_unauthenticated/privacy": typeof UnauthenticatedPrivacyRoute;
  "/_unauthenticated/terms": typeof UnauthenticatedTermsRoute;
  "/_admin/library/documents": typeof AdminLibraryDocumentsRouteRouteWithChildren;
  "/_admin/library/plans": typeof AdminLibraryPlansRouteRouteWithChildren;
  "/_admin/library/products": typeof AdminLibraryProductsRouteRouteWithChildren;
  "/_admin/customers/$customerId": typeof AdminCustomersCustomerIdRoute;
  "/_admin/users/$userId": typeof AdminUsersUserIdRoute;
  "/_authenticated/accounts/$accountId": typeof AuthenticatedAccountsAccountIdRoute;
  "/_authenticated/gauges/$gaugeId": typeof AuthenticatedGaugesGaugeIdRoute;
  "/_authenticated/tanks/$tankId": typeof AuthenticatedTanksTankIdRoute;
  "/_god/dev/logs": typeof GodDevLogsRoute;
  "/_super/invoices/$invoiceId": typeof SuperInvoicesInvoiceIdRoute;
  "/_unauthenticated/login/forgot": typeof UnauthenticatedLoginForgotRoute;
  "/_unauthenticated/login/register": typeof UnauthenticatedLoginRegisterRoute;
  "/_admin/customers/": typeof AdminCustomersIndexRoute;
  "/_admin/library/": typeof AdminLibraryIndexRoute;
  "/_admin/users/": typeof AdminUsersIndexRoute;
  "/_authenticated/accounts/": typeof AuthenticatedAccountsIndexRoute;
  "/_authenticated/gauges/": typeof AuthenticatedGaugesIndexRoute;
  "/_authenticated/tanks/": typeof AuthenticatedTanksIndexRoute;
  "/_god/dev/": typeof GodDevIndexRoute;
  "/_super/invoices/": typeof SuperInvoicesIndexRoute;
  "/_unauthenticated/login/": typeof UnauthenticatedLoginIndexRoute;
  "/_admin/library/documents/$documentId": typeof AdminLibraryDocumentsDocumentIdRoute;
  "/_admin/library/plans/$planId": typeof AdminLibraryPlansPlanIdRoute;
  "/_admin/library/documents/": typeof AdminLibraryDocumentsIndexRoute;
  "/_admin/library/plans/": typeof AdminLibraryPlansIndexRoute;
  "/_admin/library/products/": typeof AdminLibraryProductsIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | ""
    | "/customers"
    | "/library"
    | "/users"
    | "/accounts"
    | "/gauges"
    | "/tanks"
    | "/invoices"
    | "/login"
    | "/privacy"
    | "/terms"
    | "/library/documents"
    | "/library/plans"
    | "/library/products"
    | "/customers/$customerId"
    | "/users/$userId"
    | "/accounts/$accountId"
    | "/gauges/$gaugeId"
    | "/tanks/$tankId"
    | "/dev/logs"
    | "/invoices/$invoiceId"
    | "/login/forgot"
    | "/login/register"
    | "/customers/"
    | "/library/"
    | "/users/"
    | "/accounts/"
    | "/gauges/"
    | "/tanks/"
    | "/dev"
    | "/invoices/"
    | "/login/"
    | "/library/documents/$documentId"
    | "/library/plans/$planId"
    | "/library/documents/"
    | "/library/plans/"
    | "/library/products/";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | ""
    | "/privacy"
    | "/terms"
    | "/customers/$customerId"
    | "/users/$userId"
    | "/accounts/$accountId"
    | "/gauges/$gaugeId"
    | "/tanks/$tankId"
    | "/dev/logs"
    | "/invoices/$invoiceId"
    | "/login/forgot"
    | "/login/register"
    | "/customers"
    | "/library"
    | "/users"
    | "/accounts"
    | "/gauges"
    | "/tanks"
    | "/dev"
    | "/invoices"
    | "/login"
    | "/library/documents/$documentId"
    | "/library/plans/$planId"
    | "/library/documents"
    | "/library/plans"
    | "/library/products";
  id:
    | "__root__"
    | "/"
    | "/_admin"
    | "/_authenticated"
    | "/_god"
    | "/_super"
    | "/_admin/customers"
    | "/_admin/library"
    | "/_admin/users"
    | "/_authenticated/accounts"
    | "/_authenticated/gauges"
    | "/_authenticated/tanks"
    | "/_super/invoices"
    | "/_unauthenticated/login"
    | "/_unauthenticated/privacy"
    | "/_unauthenticated/terms"
    | "/_admin/library/documents"
    | "/_admin/library/plans"
    | "/_admin/library/products"
    | "/_admin/customers/$customerId"
    | "/_admin/users/$userId"
    | "/_authenticated/accounts/$accountId"
    | "/_authenticated/gauges/$gaugeId"
    | "/_authenticated/tanks/$tankId"
    | "/_god/dev/logs"
    | "/_super/invoices/$invoiceId"
    | "/_unauthenticated/login/forgot"
    | "/_unauthenticated/login/register"
    | "/_admin/customers/"
    | "/_admin/library/"
    | "/_admin/users/"
    | "/_authenticated/accounts/"
    | "/_authenticated/gauges/"
    | "/_authenticated/tanks/"
    | "/_god/dev/"
    | "/_super/invoices/"
    | "/_unauthenticated/login/"
    | "/_admin/library/documents/$documentId"
    | "/_admin/library/plans/$planId"
    | "/_admin/library/documents/"
    | "/_admin/library/plans/"
    | "/_admin/library/products/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AdminRouteRoute: typeof AdminRouteRouteWithChildren;
  AuthenticatedRouteRoute: typeof AuthenticatedRouteRouteWithChildren;
  GodRouteRoute: typeof GodRouteRouteWithChildren;
  SuperRouteRoute: typeof SuperRouteRouteWithChildren;
  UnauthenticatedLoginRouteRoute: typeof UnauthenticatedLoginRouteRouteWithChildren;
  UnauthenticatedPrivacyRoute: typeof UnauthenticatedPrivacyRoute;
  UnauthenticatedTermsRoute: typeof UnauthenticatedTermsRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminRouteRoute: AdminRouteRouteWithChildren,
  AuthenticatedRouteRoute: AuthenticatedRouteRouteWithChildren,
  GodRouteRoute: GodRouteRouteWithChildren,
  SuperRouteRoute: SuperRouteRouteWithChildren,
  UnauthenticatedLoginRouteRoute: UnauthenticatedLoginRouteRouteWithChildren,
  UnauthenticatedPrivacyRoute: UnauthenticatedPrivacyRoute,
  UnauthenticatedTermsRoute: UnauthenticatedTermsRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_admin",
        "/_authenticated",
        "/_god",
        "/_super",
        "/_unauthenticated/login",
        "/_unauthenticated/privacy",
        "/_unauthenticated/terms"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_admin": {
      "filePath": "_admin/route.tsx",
      "children": [
        "/_admin/customers",
        "/_admin/library",
        "/_admin/users"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated/route.tsx",
      "children": [
        "/_authenticated/accounts",
        "/_authenticated/gauges",
        "/_authenticated/tanks"
      ]
    },
    "/_god": {
      "filePath": "_god/route.tsx",
      "children": [
        "/_god/dev/logs",
        "/_god/dev/"
      ]
    },
    "/_super": {
      "filePath": "_super/route.tsx",
      "children": [
        "/_super/invoices"
      ]
    },
    "/_admin/customers": {
      "filePath": "_admin/customers/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/customers/$customerId",
        "/_admin/customers/"
      ]
    },
    "/_admin/library": {
      "filePath": "_admin/library/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/library/documents",
        "/_admin/library/plans",
        "/_admin/library/products",
        "/_admin/library/"
      ]
    },
    "/_admin/users": {
      "filePath": "_admin/users/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/users/$userId",
        "/_admin/users/"
      ]
    },
    "/_authenticated/accounts": {
      "filePath": "_authenticated/accounts/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/accounts/$accountId",
        "/_authenticated/accounts/"
      ]
    },
    "/_authenticated/gauges": {
      "filePath": "_authenticated/gauges/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/gauges/$gaugeId",
        "/_authenticated/gauges/"
      ]
    },
    "/_authenticated/tanks": {
      "filePath": "_authenticated/tanks/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/tanks/$tankId",
        "/_authenticated/tanks/"
      ]
    },
    "/_super/invoices": {
      "filePath": "_super/invoices/route.tsx",
      "parent": "/_super",
      "children": [
        "/_super/invoices/$invoiceId",
        "/_super/invoices/"
      ]
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login/route.tsx",
      "children": [
        "/_unauthenticated/login/forgot",
        "/_unauthenticated/login/register",
        "/_unauthenticated/login/"
      ]
    },
    "/_unauthenticated/privacy": {
      "filePath": "_unauthenticated/privacy.tsx"
    },
    "/_unauthenticated/terms": {
      "filePath": "_unauthenticated/terms.tsx"
    },
    "/_admin/library/documents": {
      "filePath": "_admin/library/documents/route.tsx",
      "parent": "/_admin/library",
      "children": [
        "/_admin/library/documents/$documentId",
        "/_admin/library/documents/"
      ]
    },
    "/_admin/library/plans": {
      "filePath": "_admin/library/plans/route.tsx",
      "parent": "/_admin/library",
      "children": [
        "/_admin/library/plans/$planId",
        "/_admin/library/plans/"
      ]
    },
    "/_admin/library/products": {
      "filePath": "_admin/library/products/route.tsx",
      "parent": "/_admin/library",
      "children": [
        "/_admin/library/products/"
      ]
    },
    "/_admin/customers/$customerId": {
      "filePath": "_admin/customers/$customerId.tsx",
      "parent": "/_admin/customers"
    },
    "/_admin/users/$userId": {
      "filePath": "_admin/users/$userId.tsx",
      "parent": "/_admin/users"
    },
    "/_authenticated/accounts/$accountId": {
      "filePath": "_authenticated/accounts/$accountId.tsx",
      "parent": "/_authenticated/accounts"
    },
    "/_authenticated/gauges/$gaugeId": {
      "filePath": "_authenticated/gauges/$gaugeId.tsx",
      "parent": "/_authenticated/gauges"
    },
    "/_authenticated/tanks/$tankId": {
      "filePath": "_authenticated/tanks/$tankId.tsx",
      "parent": "/_authenticated/tanks"
    },
    "/_god/dev/logs": {
      "filePath": "_god/dev/logs.tsx",
      "parent": "/_god"
    },
    "/_super/invoices/$invoiceId": {
      "filePath": "_super/invoices/$invoiceId.tsx",
      "parent": "/_super/invoices"
    },
    "/_unauthenticated/login/forgot": {
      "filePath": "_unauthenticated/login/forgot.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_unauthenticated/login/register": {
      "filePath": "_unauthenticated/login/register.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_admin/customers/": {
      "filePath": "_admin/customers/index.tsx",
      "parent": "/_admin/customers"
    },
    "/_admin/library/": {
      "filePath": "_admin/library/index.tsx",
      "parent": "/_admin/library"
    },
    "/_admin/users/": {
      "filePath": "_admin/users/index.tsx",
      "parent": "/_admin/users"
    },
    "/_authenticated/accounts/": {
      "filePath": "_authenticated/accounts/index.tsx",
      "parent": "/_authenticated/accounts"
    },
    "/_authenticated/gauges/": {
      "filePath": "_authenticated/gauges/index.tsx",
      "parent": "/_authenticated/gauges"
    },
    "/_authenticated/tanks/": {
      "filePath": "_authenticated/tanks/index.tsx",
      "parent": "/_authenticated/tanks"
    },
    "/_god/dev/": {
      "filePath": "_god/dev/index.tsx",
      "parent": "/_god"
    },
    "/_super/invoices/": {
      "filePath": "_super/invoices/index.tsx",
      "parent": "/_super/invoices"
    },
    "/_unauthenticated/login/": {
      "filePath": "_unauthenticated/login/index.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_admin/library/documents/$documentId": {
      "filePath": "_admin/library/documents/$documentId.tsx",
      "parent": "/_admin/library/documents"
    },
    "/_admin/library/plans/$planId": {
      "filePath": "_admin/library/plans/$planId.tsx",
      "parent": "/_admin/library/plans"
    },
    "/_admin/library/documents/": {
      "filePath": "_admin/library/documents/index.tsx",
      "parent": "/_admin/library/documents"
    },
    "/_admin/library/plans/": {
      "filePath": "_admin/library/plans/index.tsx",
      "parent": "/_admin/library/plans"
    },
    "/_admin/library/products/": {
      "filePath": "_admin/library/products/index.tsx",
      "parent": "/_admin/library/products"
    }
  }
}
ROUTE_MANIFEST_END */
