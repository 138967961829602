import { IconEye, IconEyeOff, IconSearch } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { format, subHours } from "date-fns";
import { useState } from "react";
import { z } from "zod";

import {
  Button,
  List,
  ListInput,
  StatsHeader,
  TablePage,
  TextInput,
  Time,
  Tree,
  pageParts,
} from "../../../components";
import { log, logGroupsQuery, logsQuery } from "../../../data";
import { useDebounce, useStorage, useTable } from "../../../hooks";

export const Route = createFileRoute("/_god/dev/logs")({
  component: Component,
});

const levelColours = {
  INFO: "bg-sky-100",
  ERROR: "bg-red-100",
  WARN: "bg-yellow-100",
  DEBUG: "bg-blue-100",
};

export function Component() {
  const logGroups = useInfiniteQuery(logGroupsQuery());
  const [group, setGroup] = useStorage({
    defaultValue: undefined,
    key: "logs-group",
    schema: z.string().optional(),
    storage: "session",
  });
  const [from, setFrom] = useStorage({
    defaultValue: subHours(new Date(), 2),
    key: "logs-from",
    schema: z.coerce.date().optional(),
    storage: "session",
  });
  const [filter, setFilter] = useStorage({
    defaultValue: undefined,
    key: "logs-filter",
    schema: z.string().optional(),
    storage: "session",
  });

  const queryOptions = useDebounce(() => ({ from, filter }), 500, [
    from,
    filter,
  ]);
  const logs = useTable(logsQuery(group, queryOptions), {
    columnDefs: (c) => [
      c.accessor("timestamp", {
        meta: {
          className: "text-xs flex-1",
        },
        cell: (v) => <Time date={v.getValue()} />,
      }),
      c.accessor("requestId", {
        meta: {
          className: "text-xs",
        },
      }),
      c.accessor("ipAddress", {
        meta: {
          className: "text-xs",
        },
      }),
      c.accessor("level", {
        meta: {
          className: "text-xs",
        },
        cell: (v) => (
          <div
            className={clsx(
              "rounded-full px-3 py-0.5 tracking-wide",
              levelColours[v.getValue() as keyof typeof levelColours] ||
                "bg-gray-100",
            )}
          >
            {v.getValue() || "INFO"}
          </div>
        ),
      }),
      c.accessor("parsed", {
        meta: {
          className: "w-full",
        },
        cell: (v) => v.getValue()?.map((item) => <Tree data={item} />),
      }),
      c.accessor("content", {
        meta: {
          className: "w-full",
        },
        cell: (v) => {
          const [show, setShow] = useState(false);
          return (
            v.getValue() && (
              <div>
                <span
                  className={clsx(
                    "whitespace-pre-line text-wrap break-all font-mono text-sm",
                    show ? "" : "line-clamp-1",
                  )}
                >
                  {v.getValue()}
                </span>
                <Button
                  className="text-xs"
                  kind="menu"
                  icon={show ? IconEyeOff : IconEye}
                  text={show ? "Hide Log" : "Show Log"}
                  onClick={() => setShow(!show)}
                />
              </div>
            )
          );
        },
      }),
    ],
    canFilter: false,
    word: log,
  });

  return (
    <TablePage
      {...logs}
      views={{ table: List }}
      actions={
        <div className={pageParts.actionItems}>
          <div>
            <ListInput
              placeholder="Select a log group"
              options={logGroups.data || []}
              value={group}
              onChange={setGroup}
            />
          </div>
          <div>
            <TextInput
              className="max-w-80"
              icon={IconSearch}
              placeholder="Filter logs..."
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
            />
          </div>
          <div className="flex-1" />
          <div>
            <TextInput
              placeholder="From"
              type="datetime-local"
              value={from ? format(from, "yyyy-MM-dd'T'HH:mm") : ""}
              onChange={(e) =>
                setFrom(
                  e.currentTarget.value
                    ? new Date(e.currentTarget.value)
                    : undefined,
                )
              }
            />
          </div>
        </div>
      }
    >
      <StatsHeader
        stats={[
          {
            label: "Log Groups",
            value: logGroups.data?.length,
          },
          ...logs.stats,
        ]}
      />
    </TablePage>
  );
}
