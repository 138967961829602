import { ReactNode } from "@tanstack/react-router";
import domToImage from "dom-to-image";
import { createContext, useContext, useState } from "react";

export type GlobalPanel = "feedback" | "profile";

export type GlobalPanelContextProps = {
  panel: GlobalPanel | undefined;
  setPanel: (panel: GlobalPanel | undefined) => void;
  screenshot: Blob | undefined;
  setScreenshot: (screenshot: Blob) => void;
  captureScreenshot: () => void;
};

export const GlobalPanelContext = createContext<GlobalPanelContextProps>({
  panel: undefined,
  setPanel: () => {},
  screenshot: undefined,
  setScreenshot: () => {},
  captureScreenshot: () => {},
});

export const FeedbackProvider = ({ children }: { children: ReactNode }) => {
  const [panel, setPanel] = useState<GlobalPanel>();
  const [screenshot, setScreenshot] = useState<Blob>();

  return (
    <GlobalPanelContext.Provider
      value={{
        panel,
        setPanel,
        screenshot,
        setScreenshot,
        captureScreenshot: () =>
          domToImage.toBlob(document.body).then((blob) => setScreenshot(blob)),
      }}
    >
      {children}
    </GlobalPanelContext.Provider>
  );
};

export const useGlobalPanel = () => useContext(GlobalPanelContext);
