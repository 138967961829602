import { IconMessageReport } from "@tabler/icons-react";
import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import clsx from "clsx";

import { backgrounds, logo } from "../../../assets";
import { Button, LinkButton, NotFound, scheme } from "../../../components";
import { useGlobalPanel } from "../../../hooks";

export const Route = createFileRoute("/_unauthenticated/login")({
  beforeLoad: async ({ context: { auth } }) => {
    if (auth.isAuthenticated) throw redirect({ to: "/" });
  },
  component: Component,
  notFoundComponent: () => (
    <NotFound
      item="Page"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  ),
});

export function Component() {
  const { captureScreenshot, setPanel } = useGlobalPanel();

  return (
    <div className={clsx("absolute inset-0 flex", scheme.standard)}>
      <div className="flex flex-1 flex-col justify-center bg-white px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="flex-1" />
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <img className="mb-10 h-10 w-auto" src={logo} alt="Joylevel" />
          <Outlet />
        </div>
        <div className="mx-auto mt-10 flex w-full max-w-sm flex-col justify-center text-sm font-normal sm:flex-row sm:divide-x sm:divide-gray-300">
          <LinkButton
            kind="link"
            variant="cancel"
            text="Privacy Policy"
            to="/privacy"
            target="_blank"
            className="py-2 sm:px-3"
          />
          <LinkButton
            kind="link"
            variant="cancel"
            text="Terms of service"
            to="/terms"
            target="_blank"
            className="py-2 sm:px-3"
          />
          <Button
            kind="link"
            variant="cancel"
            onClick={() => {
              captureScreenshot();
              setPanel("feedback");
            }}
            icon={IconMessageReport}
            text="Help & Feedback"
            className="py-2 sm:px-3"
          />
        </div>
        <div className="flex-[3]" />
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="pointer-events-none absolute inset-0 size-full object-cover"
          src={backgrounds.tankLandscape}
          alt=""
        />
      </div>
    </div>
  );
}
