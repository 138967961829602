import clsx from "clsx";
import { ReactNode } from "react";

export type HeaderProps = {
  className?: string;
  children: ReactNode;
};

export const Header = ({ className, children }: HeaderProps) => (
  <div className="relative isolate -mt-16 pb-3 pt-14 sm:pb-6 sm:pt-20">
    <div
      className="absolute inset-0 -z-10 -mx-3 overflow-hidden"
      aria-hidden="true"
    >
      <div className="absolute left-16 top-full -mt-14 opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
        <div
          className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-emerald-800 to-emerald-400"
          style={{
            clipPath:
              "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
          }}
        />
      </div>
      <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
    </div>
    <div
      className={clsx(
        "container mx-auto flex items-center justify-between gap-x-8",
        className,
      )}
    >
      {children}
    </div>
  </div>
);

export type ItemHeaderProps = {
  image?: string;
  title: string;
  subtitle?: ReactNode;
  children?: ReactNode;
};

export const ItemHeader = ({
  image,
  title,
  subtitle,
  children,
}: ItemHeaderProps) => (
  <Header>
    <div className="flex w-full items-center gap-6">
      {image && <img src={image} alt="" className="w-11 flex-none" />}
      <div className="flex min-w-0 flex-col items-start gap-1">
        <h1 className="max-w-full truncate text-2xl font-semibold">{title}</h1>
        {subtitle && (
          <h2 className="max-w-full truncate text-sm font-normal text-gray-600">
            {subtitle}
          </h2>
        )}
      </div>
      {children}
    </div>
  </Header>
);
