import { brandIcons } from "../../assets";
import {
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  stackCell,
} from "../../components";
import { account, planAccountsQuery } from "../../data";
import { useTable } from "../../hooks";

export const PlanAccountsPanel = ({ planId }: { planId: string }) => {
  const accounts = useTable(planAccountsQuery(planId), {
    columnDefs: (c) => [
      c.accessor(
        "product",
        imageCell({
          header: "",
          icons: brandIcons,
        }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (a) => [a.customer?.name],
        }),
      ),
      c.accessor("customer.name", {
        header: "Customer",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.display(actionCell()),
    ],
    select: (accountId) => ({
      to: "/accounts/$accountId",
      params: { accountId },
    }),
    word: account,
  });

  return <TablePage {...accounts} />;
};
