import { useRouterState } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";

export const Head = () => {
  const title = useRouterState({
    select: (s) =>
      [
        ...s.matches
          .map((m) => m.meta?.find((t) => t?.title)?.title)
          .filter(Boolean)
          .reverse(),
        "Joylevel",
      ].join(" | "),
  });

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};
