import {
  infiniteQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";

import { useClearParams } from "../../hooks";
import { request, requestFn } from "./base";
import {
  CommentEntity,
  CommentsDocument,
  CreateCommentDocument,
  DeleteCommentDocument,
} from "./operations.generated";

export const commentsQuery = (entityType: CommentEntity, entityId: string) =>
  infiniteQueryOptions({
    queryKey: ["comments", entityType, entityId],
    queryFn: ({ pageParam }) =>
      request(CommentsDocument, {
        entityType,
        entityId,
        limit: 100,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.comments.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.comments.comments),
  });

const createCommentFn = requestFn(CreateCommentDocument);

export const useCreateComment = (props: {
  entityType: CommentEntity;
  entityId: string;
}) => {
  const [commentId, setCommentId] = useState(() => crypto.randomUUID());
  const [message, setMessage] = useState("");

  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: createCommentFn,
    onSuccess: async (result, variables) => {
      if (variables.fields.entityType && variables.fields.entityId) {
        queryClient.setQueryData(
          commentsQuery(variables.fields.entityType, variables.fields.entityId)
            .queryKey,
          (previous) =>
            previous
              ? {
                  ...previous,
                  pages: [
                    {
                      ...previous.pages[0],
                      comments: {
                        ...previous.pages[0]?.comments,
                        comments: [
                          result.createComment,
                          ...(previous.pages[0]?.comments.comments || []),
                        ],
                      },
                    },
                    ...previous.pages.slice(1),
                  ],
                }
              : undefined,
        );
      }
    },
  });

  return {
    id: commentId,
    initialValue: message,
    loading: isPending,
    onChange: (v: string) => setMessage(v),
    onSubmit: async () => {
      await mutateAsync({
        id: commentId,
        fields: { ...props, message: message },
      });
      setMessage("");
      setCommentId(crypto.randomUUID());
    },
  };
};

const deleteCommentFn = requestFn(DeleteCommentDocument);

export const useDeleteComment = (
  entityType: CommentEntity,
  entityId: string,
  commentId: string | undefined,
) => {
  const onClose = useClearParams();
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteCommentFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteComment) {
        queryClient.setQueryData(
          commentsQuery(entityType, entityId).queryKey,
          (existing) => {
            if (!existing) return undefined;

            return {
              ...existing,
              pages: existing.pages.map((p) => ({
                ...p,
                comments: {
                  ...p.comments,
                  comments: p.comments.comments.filter((u) => u.id !== id),
                },
              })),
            };
          },
        );
        onClose();
      }
    },
  });

  return {
    error,
    isPending,
    onDelete: () => commentId && mutateAsync({ id: commentId }),
  };
};
