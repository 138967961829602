import { useInfiniteQuery } from "@tanstack/react-query";

import { ComboField, SlideContent, TextField } from "../../components";
import { customersQuery, plansQuery, useUpdateAccount } from "../../data";

export const AccountEditContent = ({ accountId }: { accountId: string }) => {
  const { data, error, form, validators } = useUpdateAccount(accountId);
  const customers = useInfiniteQuery(customersQuery());
  const plans = useInfiniteQuery(plansQuery());

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the account information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Account name" autoFocus />
        )}
      />
      <form.Field
        name="customer"
        validators={validators.customer}
        children={(field) => (
          <ComboField
            field={field}
            label="Customer"
            options={customers.data || []}
            accessors={[(o) => o.name]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.name}
            immediate
          />
        )}
      />
      <form.Field
        name="plan"
        validators={validators.plan}
        children={(field) => (
          <ComboField
            field={field}
            label="Plan"
            options={plans.data || []}
            accessors={[(o) => o.code]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.code}
            immediate
          />
        )}
      />
    </SlideContent>
  );
};
