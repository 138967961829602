export const specialChars = {
  dot: "\u22C5",
  endash: "\u2013",
  plusMinus: "\u00B1",
  nbsp: "\u00A0",
  masked: "\u2022",
};

export const tw = (strings: TemplateStringsArray, ...values: unknown[]) =>
  String.raw({ raw: strings }, ...values);
