import { Transition } from "@headlessui/react";
import {
  IconGridDots,
  IconMap,
  IconSortAscendingNumbers,
  IconSortDescendingNumbers,
  IconTable,
} from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { format } from "date-fns";
import { useMemo } from "react";
import { z } from "zod";

import { downloadCSV } from "@joy/shared-utils";

import { specialChars } from "../../../assets";
import {
  Grid,
  ListInput,
  Locations,
  StatsHeader,
  Table,
  TablePage,
  pageParts,
  transitions,
} from "../../../components";
import {
  listLoader,
  sumVolume,
  tank,
  tanksQuery,
  useAuth,
} from "../../../data";
import { useTable } from "../../../hooks";
import { tankColumns } from "../../../panels";

export const Route = createFileRoute("/_authenticated/tanks/")({
  loader: async ({ context }) => listLoader(context.queryClient, tanksQuery()),
  component: Component,
});

const viewSchema = z.enum(["grid", "table", "map"]);

const sortLabel = (o: "asc" | "desc") => {
  const Icon = {
    asc: IconSortAscendingNumbers,
    desc: IconSortDescendingNumbers,
  }[o];
  return <Icon className="size-6 py-0.5" aria-label={o} />;
};

export function Component() {
  const { hasTeamPermission } = useAuth();
  const tanks = useTable(tanksQuery(), {
    columnDefs: tankColumns,
    initialSort: [{ id: "level_updatedAt", desc: true }],
    statFns: [
      (filtered) => ({
        label: "Volume",
        value:
          sumVolume(filtered, (i) => [i.level?.value, i.unit]) ||
          specialChars.endash,
      }),
      (filtered) => ({
        label: "Ullage",
        value:
          sumVolume(filtered, (i) => [
            i.level ? i.level.maximum - i.level.value : undefined,
            i.unit,
          ]) || specialChars.endash,
      }),
    ],
    select: (tankId) => ({ to: "/tanks/$tankId", params: { tankId } }),
    download: (filtered) =>
      downloadCSV(filtered, {
        cells: [
          "id",
          "account.name",
          "name",
          "activatedAt",
          "account.customer.name",
          "account.plan.code",
          "product.label",
          "level.updatedAt",
          "level.value",
          "level.maximum",
          "level.percent",
          "level.voltage",
          "level.temperature",
        ],
        headers: {
          id: "ID",
          "account.name": "Account",
          name: "Tank",
          activatedAt: "Activation Date",
          "account.customer.name": "Customer",
          "account.plan.code": "Plan",
          "product.label": "Contents",
          "level.updatedAt": "Latest Update",
          "level.value": "Level",
          "level.maximum": "SFL",
          "level.percent": "Level Percent",
          "level.voltage": "Battery Percent",
          "level.temperature": "Temperature",
        },
        formats: {
          activatedAt: "date",
          "level.updatedAt": "dateTime",
        },
        visibility: {
          "account.customer.name": hasTeamPermission("admin"),
          "account.plan.code": hasTeamPermission("admin"),
        },
        file: `tanks-${format(new Date(), "yyyy-MM-dd")}`,
      }),
    initialVisibility: {
      latitude: false,
      longitude: false,
    },
    view: {
      options: viewSchema,
      optionLabel: (o) => {
        const Icon = {
          table: IconTable,
          grid: IconGridDots,
          map: IconMap,
        }[o];
        return <Icon className="size-6 py-0.5" aria-label={o} />;
      },
    },
    word: tank,
  });

  const columnHeaders = useMemo(
    () =>
      Object.fromEntries(
        tanks.table.table
          .getAllLeafColumns()
          .map((column) => [column.id, column.columnDef.header?.toString()]),
      ),
    [tanks.table.table.getAllLeafColumns()],
  );

  return (
    <TablePage
      {...tanks}
      views={{
        table: Table,
        grid: Grid,
        map: Locations as typeof Table,
      }}
      actions={
        <Transition show={tanks.view.value === "grid"}>
          <div
            className={clsx(
              pageParts.actionItems,
              "justify-end",
              transitions.fade,
            )}
          >
            <div>
              <ListInput
                className="min-w-44"
                options={[
                  "account_name",
                  "level_voltage",
                  "level_percent",
                  "account_product",
                  "name",
                  "level_updatedAt",
                  "product_label",
                ]}
                optionLabel={(o) => columnHeaders[o]}
                {...tanks.sort[0]}
              />
            </div>
            <div>
              <ListInput
                options={["asc", "desc"]}
                {...tanks.sort[1]}
                optionLabel={sortLabel}
              />
            </div>
          </div>
        </Transition>
      }
    >
      <StatsHeader stats={tanks.stats} />
    </TablePage>
  );
}
