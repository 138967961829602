import { toTitle } from "@joy/shared-utils";

import {
  SlideContent,
  TablePage,
  cellKinds,
  dateCell,
  stackCell,
} from "../../components";
import { invitesQuery, user } from "../../data";
import { useTable } from "../../hooks";

export const InvitesContent = () => {
  const invites = useTable(invitesQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "email",
        stackCell({
          header: "Email",
          detail: (invite) => [toTitle(invite.jlteam || "user")],
        }),
      ),
      c.accessor("jlteam", {
        header: "Role",
        cell: (c) => toTitle(c.getValue() || "user"),
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "createdAt",
        dateCell({
          header: "Sent",
        }),
      ),
    ],
    word: user,
  });

  return (
    <SlideContent title="Pending invites">
      <div className="px-6">
        <TablePage {...invites} variant="slide" />
      </div>
    </SlideContent>
  );
};
