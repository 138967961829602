import { IconMailCheck } from "@tabler/icons-react";

import { isKeyOf, mapOrEmpty } from "@joy/shared-utils";

import { loginIcons, specialChars } from "../../assets";
import {
  Field,
  FormError,
  FormSuccess,
  Label,
  SlideContent,
  TextField,
  fieldParts,
} from "../../components";
import { useUpdateProfile } from "../../data";

export const ProfileContent = () => {
  const { form, validators, user } = useUpdateProfile();

  return (
    <SlideContent
      title="Update your profile"
      description="Update and save your profile information below."
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <Field>
        <Label>Email address</Label>
        <div className={fieldParts.row}>
          <p>{user?.primaryEmailAddress?.emailAddress}</p>
          {user?.primaryEmailAddress?.verification.status === "verified" ? (
            <FormSuccess icon={IconMailCheck} text="Verified" />
          ) : (
            <FormError error={new Error("Unverified")} />
          )}
        </div>
      </Field>
      <Field>
        <Label>Linked accounts</Label>
        <div className={fieldParts.row}>
          {mapOrEmpty(
            user?.externalAccounts.map((account) => account.provider),
            (provider) =>
              isKeyOf(loginIcons, provider) ? (
                <img
                  className="size-6"
                  src={loginIcons[provider]}
                  alt={provider}
                />
              ) : (
                <p>{provider}</p>
              ),
            <p>{specialChars.endash}</p>,
          )}
        </div>
      </Field>
      <div className={fieldParts.row}>
        <form.Field
          name="firstName"
          validators={validators.firstName}
          children={(field) => <TextField field={field} label="First name" />}
        />
        <form.Field
          name="lastName"
          validators={validators.lastName}
          children={(field) => <TextField field={field} label="Last name" />}
        />
      </div>
      <form.Field
        name="phone"
        children={(field) => (
          <TextField field={field} label="Phone number" inputMode="tel" />
        )}
      />
      <form.Field
        name="company"
        children={(field) => <TextField field={field} label="Company" />}
      />
      <form.Field
        name="title"
        children={(field) => <TextField field={field} label="Title" />}
      />
    </SlideContent>
  );
};
