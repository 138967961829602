import { useStore } from "@tanstack/react-form";
import { useInfiniteQuery } from "@tanstack/react-query";

import { ComboField, SlideContent, TextField } from "../../components";
import {
  customerAccountsQuery,
  customersQuery,
  useAuth,
  useUpdateGauge,
} from "../../data";

export const GaugeEditContent = ({ gaugeId }: { gaugeId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data, error, form, validators } = useUpdateGauge(gaugeId);
  const customers = useInfiniteQuery(customersQuery());
  const customerId = useStore(
    form.store,
    (s) => s.values.customer?.id || undefined,
  );
  const accounts = useInfiniteQuery(customerAccountsQuery(customerId));

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the gauge information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Gauge name" autoFocus />
        )}
      />
      {hasTeamPermission("admin") && (
        <form.Field
          name="customer"
          validators={validators.customer}
          children={(field) => (
            <ComboField
              field={field}
              label="Customer"
              options={customers.data || []}
              accessors={[(o) => o.name]}
              optionKey={(o) => o.id}
              optionLabel={(o) => o.name}
              immediate
            />
          )}
        />
      )}
      <form.Field
        name="account"
        validators={validators.account}
        children={(field) => (
          <ComboField
            field={field}
            label="Account"
            options={
              accounts.data?.filter((a) => a.product === "JoyGauge") || []
            }
            accessors={[(o) => o.name]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.name}
            immediate
          />
        )}
      />
    </SlideContent>
  );
};
