import { useForm } from "@tanstack/react-form";
import { useMutation } from "@tanstack/react-query";
import { zodValidator } from "@tanstack/zod-form-adapter";
import ky from "ky";
import { useEffect, useState } from "react";
import { z } from "zod";

import { convertLexical } from "../../components";
import { useGlobalPanel, useValidators } from "../../hooks";
import { useAuth, userName } from "../auth";

const validation = {
  email: z.string().email("Please enter a valid email"),
  type: z.enum(["Problem", "Feature Request", "Service Request"]),
  description: z.string().min(1, "Please describe your request"),
};

type FeedbackType = z.infer<(typeof validation)["type"]>;

const freshdesk = ky.extend({
  prefixUrl: "https://joylevel.freshdesk.com/api/widget",
  headers: {
    "X-Widget-Id": "43000003437",
    "X-Client-Id": "730bd6e8-6765-9930-b7d4-6b4c0eb2d863",
  },
});

const attachmentSchema = z.object({ id: z.number() });

export const sendFeedbackAttachment = async (
  file: Blob,
  fileName = "screenshot.png",
): Promise<number | undefined> => {
  try {
    const body = new FormData();
    body.append("content", file, fileName);

    const result = await freshdesk
      .post("attachments", {
        body,
      })
      .json();
    const parsed = attachmentSchema.parse(result);

    return parsed.id;
  } catch {
    /* empty */
  }
};

const sendFeedbackFn = async ({
  name,
  email,
  type,
  description,
  attachment,
}: {
  name: string;
  email: string;
  type: FeedbackType;
  description: string;
  attachment?: Promise<number | undefined>;
}) => {
  const attachment_ids = [];
  if (attachment) {
    try {
      const id = await attachment;
      if (id) attachment_ids.push(id);
    } catch {
      /* empty */
    }
  }

  await freshdesk
    .post("tickets", {
      json: {
        name,
        email,
        type,
        attachment_ids,
        description: await convertLexical(description, "text"),
        subject: `Web Request - ${type}`,
      },
    })
    .json();
};

export const useSendFeedback = () => {
  const { isAuthenticated, email, client } = useAuth();
  const { setPanel, screenshot } = useGlobalPanel();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: sendFeedbackFn,
    onSuccess: async () => {
      setPanel(undefined);
    },
  });

  const [attachment, setAttachment] = useState<Promise<number | undefined>>();
  useEffect(() => {
    if (screenshot) setAttachment(sendFeedbackAttachment(screenshot));
  }, [screenshot]);

  const form = useForm({
    defaultValues: {
      email: email || "",
      name: userName(client.user, { hideDash: true }),
      type: "Problem" as FeedbackType,
      description: "",
    },
    onSubmit: ({ value }) =>
      mutateAsync({
        ...value,
        attachment,
      }),
    onSubmitInvalid: () => reset(),
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators, isAuthenticated };
};
