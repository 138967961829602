import { ComboboxProps } from "@headlessui/react";
import { IconMapPin } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { ReactNode, useState } from "react";

import {
  AddressLookup,
  currentLocation,
  geocode,
  geocodeQuery,
} from "../../data";
import { useDebounce } from "../../hooks";
import { Button } from "../button";
import { ComboBase } from "./combo";
import { FieldError } from "./error";
import { Field, Label, fieldKinds, labelKinds } from "./parts";
import { TextInput, TextInputProps, inputParts } from "./text";

type AddressInputProps = TextInputProps & {
  id?: string;
  className?: string;
  placeholder?: string;
  invalid?: boolean;
};

export const AddressInput = (
  props: AddressInputProps & ComboboxProps<AddressLookup, false>,
) => {
  const [sessionId] = useState(() => crypto.randomUUID());
  const [location] = useState(async () => currentLocation());
  const [query, setQuery] = useState("");
  const debounced = useDebounce(() => query, 200, [query]);
  const { data, isLoading } = useQuery(
    geocodeQuery(sessionId, debounced, location),
  );

  return (
    <ComboBase
      {...props}
      setQuery={setQuery}
      query={query}
      filtered={data || []}
      openButton={false}
      optionKey={(o) => o.id}
      optionLabel={(o) => o.display}
      loading={
        <IconMapPin
          className={clsx(
            inputParts.icon,
            isLoading ? "animate-pulse" : "opacity-40",
          )}
        />
      }
    />
  );
};

export const AddressInputField = ({
  field,
  ...props
}: { field: FieldApi<any, any, any, any, AddressLookup> } & AddressInputProps &
  ComboboxProps<AddressLookup, false>) => (
  <AddressInput
    id={field.name}
    name={field.name}
    value={field.state.value}
    onChange={(e) => field.handleChange(e as AddressLookup)}
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const AddressField = ({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, any>;
  label: ReactNode;
  fieldKind?: keyof typeof fieldKinds;
} & AddressInputProps &
  ComboboxProps<AddressLookup, false>) => {
  const [advanced, setAdvanced] = useState(false);
  return (
    <Field kind={fieldKind}>
      <Label htmlFor={field.name}>{label}</Label>
      {advanced ? (
        <div className="flex flex-col gap-1">
          <TextInput
            placeholder="Address"
            value={field.state.value?.display || ""}
            onChange={(e) =>
              field.handleChange({
                ...field.state.value,
                display: e.currentTarget.value,
              })
            }
          />
          <div className="flex gap-1">
            <TextInput
              placeholder="Latitude"
              value={field.state.value?.latitude || ""}
              onChange={(e) =>
                field.handleChange({
                  ...field.state.value,
                  latitude: parseFloat(e.currentTarget.value),
                })
              }
            />
            <TextInput
              placeholder="Longitude"
              value={field.state.value?.longitude || ""}
              onChange={(e) =>
                field.handleChange({
                  ...field.state.value,
                  longitude: parseFloat(e.currentTarget.value),
                })
              }
            />
          </div>
        </div>
      ) : (
        <AddressInputField field={field} {...rest} />
      )}
      <div className={clsx(labelKinds.error, "flex justify-between")}>
        <Button
          kind="link"
          variant="action"
          onClick={async () => {
            const results = await geocode.reverse(await currentLocation());
            field.handleChange(results[0]);
          }}
          text="Use Current Location"
        />
        <Button
          kind="link"
          variant="action"
          onClick={() => setAdvanced(!advanced)}
          text={advanced ? "Use Simple Mode" : "Use Advanced Mode"}
        />
      </div>
      <FieldError field={field} />
    </Field>
  );
};
