import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { useDeleteProduct } from "../../data";

export const ProductDeleteContent = ({
  customerId,
  productId,
}: {
  customerId?: string;
  productId: string;
}) => {
  const { data, error, isPending, onDelete } = useDeleteProduct(
    productId,
    customerId,
  );

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete "${data.product?.label}"?`}
      description={`Are you sure you want to delete "${data.product?.label}"? This action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Deleting..." }}
      buttons={[
        { text: "Delete", type: "submit", variant: "danger", autoFocus: true },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
