import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { PlanCreateContent } from "./create";

export const PlansActionPanel = () => {
  const { Slide } = useMatch({
    from: "/_admin/library/plans/",
    select: (s) => {
      switch (s.search.action) {
        case "create":
          return { Slide: PlanCreateContent };
      }

      return {};
    },
  });

  return <Actions slide={Slide && <Slide />} />;
};
