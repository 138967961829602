import { formatRelative } from "date-fns";

import { Header } from "./header";

export type DocumentProps = {
  title: string;
  html: string;
  updatedAt: Date;
};

export const Document = ({ title, html, updatedAt }: DocumentProps) => (
  <>
    <Header>
      <div className="prose mx-auto flex-1 pb-4 pt-8">
        <p className="mb-2 text-base font-normal lg:mb-2">
          Updated {formatRelative(updatedAt, new Date())}
        </p>
        <h1 className="m-0 lg:m-0">{title}</h1>
      </div>
    </Header>
    <article
      className="prose container mx-auto mb-8 py-4"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </>
);
