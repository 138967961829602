import ky from "ky";
import { z } from "zod";

const documentSchema = z.object({
  id: z.string(),
  title: z.string(),
  html: z.string().default(""),
  updatedAt: z.coerce.date(),
});

export const loadDocument = async (id: string) => {
  const result = await ky.get("/api/document", { searchParams: { id } }).json();
  const document = documentSchema.parse(result);

  return document;
};
