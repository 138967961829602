import { ReactNode } from "react";

import { isNotNullOrUndefined } from "@joy/shared-utils";

export type IndicatorProps = {
  label: string;
  icon: ReactNode;
  value: ReactNode;
};

export const Indicator = ({ label, icon, value }: IndicatorProps) => (
  <div className="flex flex-row items-center gap-2 px-2">
    <div className="text-base font-medium text-gray-500">{icon}</div>
    <div className="sr-only">{label}</div>
    <div className="text-base font-medium text-gray-900">{value}</div>
  </div>
);

export const IndicatorRow = ({
  indicators,
}: {
  indicators: (IndicatorProps | undefined | null)[];
}) => (
  <div className="mb-5 mt-3 flex items-center justify-center divide-x divide-dotted divide-gray-800">
    {indicators.filter(isNotNullOrUndefined).map((indicator) => (
      <Indicator key={indicator.label} {...indicator} />
    ))}
  </div>
);
