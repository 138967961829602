import configureMeasurements from "convert-units";
import volume, { VolumeUnits } from "convert-units/definitions/volume";

import { specialChars } from "../../assets";

const locale = Intl.DateTimeFormat().resolvedOptions().locale;

export const convert = configureMeasurements({
  volume,
});

export const displayVolume = (
  value: number | null | undefined,
  unit: VolumeUnits | null | undefined,
  maxDigits: number = 2,
) => {
  if (value === undefined || value === null) return undefined;
  const converted = convert(value)
    .from(unit || "l")
    .toBest({ cutOffNumber: 10, exclude: ["kanna", "dm3"] });
  if (!converted) return `${specialChars.endash} l`;

  return `${converted.val.toLocaleString(locale, { maximumFractionDigits: maxDigits })} ${converted.unit}`;
};

export const displayVolumeRatio = (
  value: number | null | undefined,
  max: number | null | undefined,
  unit: VolumeUnits | null | undefined,
  maxDigits: number = 2,
) => {
  if (value === undefined || value === null) return undefined;

  const convertedValue = convert(value)
    .from(unit || "l")
    .toBest({ cutOffNumber: 10, exclude: ["kanna", "dm3"] });
  const convertedMax =
    max === undefined || max === null
      ? undefined
      : convert(max)
          .from(unit || "l")
          .toBest({ cutOffNumber: 10, exclude: ["kanna", "dm3"] });

  if (!convertedValue) return `${specialChars.endash} l`;

  const valueNumber = convertedValue.val.toLocaleString(locale, {
    maximumFractionDigits: maxDigits,
  });
  const valueUnit =
    convertedMax && convertedMax.unit !== convertedValue.unit
      ? ` ${convertedValue.unit}`
      : "";
  const maxNumber =
    convertedMax?.val.toLocaleString(locale, {
      maximumFractionDigits: maxDigits,
    }) || specialChars.endash;
  const maxUnit = convertedMax?.unit || convertedValue.unit;

  return `${valueNumber}${valueUnit} / ${maxNumber} ${maxUnit}`;
};

export const displayPrice = (amount: number | null | undefined) =>
  typeof amount !== "number"
    ? undefined
    : (amount / 100).toLocaleString(locale, {
        style: "currency",
        currency: "AUD",
      });

export const displayPercent = (percent: number | null | undefined) =>
  typeof percent !== "number"
    ? undefined
    : (percent / 100).toLocaleString(locale, { style: "percent" });

export const displayTemperature = (temperature: number | null | undefined) =>
  typeof temperature !== "number"
    ? undefined
    : temperature.toLocaleString(locale, {
        style: "unit",
        unit: "celsius",
        maximumFractionDigits: 1,
      });

export const displaySignal = (csq: number | null | undefined) =>
  typeof csq !== "number" ? undefined : `${csq * 2 - 113} dBm`;

export const sumVolume = <I>(
  items: I[],
  fn: (i: I) => [number | undefined, VolumeUnits | null | undefined],
) => {
  const sum = items.reduce((acc, item) => {
    const [value, unit] = fn(item);
    const converted =
      value && unit && unit !== "l"
        ? convert(value).from(unit).to("l")
        : value || 0;
    return acc + converted;
  }, 0);

  return displayVolume(sum, "l");
};
