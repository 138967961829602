import { SlideContent, TextField } from "../../components";
import { useUpdateCustomer } from "../../data";

export const CustomerEditContent = ({ customerId }: { customerId: string }) => {
  const { data, error, form, validators } = useUpdateCustomer(customerId);

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the customer information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Customer name" autoFocus />
        )}
      />
    </SlideContent>
  );
};
