import { IconReceiptDollar } from "@tabler/icons-react";
import { infiniteQueryOptions } from "@tanstack/react-query";
import { addMonths, format, getMonth, getYear } from "date-fns";
import { z } from "zod";

import { range } from "@joy/shared-utils";

import { useStorage } from "../../hooks";
import { Word } from "../helpers";
import { request } from "./base";
import { InvoicesDocument } from "./operations.generated";

export const invoice: Word = {
  icon: IconReceiptDollar,
  article: "an",
  singular: "invoice",
  plural: "invoices",
};

export const defaultCalendar = {
  month: getMonth(new Date()),
  year: getYear(new Date()),
};

export const calendarToString = (calendar: { month: number; year: number }) =>
  `${calendar.year}-${calendar.month + 1}`;

export const monthOptions = range(0, 11);

export const yearOptions = range(2023, defaultCalendar.year + 2);

export const calendarSchema = z.object({
  month: z.number(),
  year: z.number(),
});

export const useInvoicesCalendar = () => {
  const [calendar, setCalendar] = useStorage({
    defaultValue: defaultCalendar,
    key: "invoices-calendar",
    schema: calendarSchema,
    storage: "session",
  });

  return {
    calendar: calendarToString(calendar),
    month: {
      options: monthOptions,
      optionLabel: (month: number) =>
        format(new Date(defaultCalendar.year, month), "MMM"),
      value: calendar.month,
      onChange: (month: number) => setCalendar({ ...calendar, month }),
    },
    year: {
      options: yearOptions,
      value: calendar.year,
      onChange: (year: number) => setCalendar({ ...calendar, year }),
    },
    adjust: (months: number) => {
      const date = addMonths(new Date(calendar.year, calendar.month), months);
      setCalendar({
        month: getMonth(date),
        year: getYear(date),
      });
    },
  };
};

export const invoicesQuery = (month: string) =>
  infiniteQueryOptions({
    queryKey: ["invoices", month],
    queryFn: ({ pageParam }) =>
      request(InvoicesDocument, {
        month: month,
        limit: 100,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.invoices.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.invoices.invoices),
  });
