import { unique } from "@joy/shared-utils";

import { ListField, TextField, fieldParts } from "../../components";
import {
  Column,
  UploadGaugesContentProps,
  defaultUploadGaugeFields,
  isModelGauge,
  loadWorkbook,
} from "../../data";

const typeLabel = {
  column: "Column",
  static: "Value",
};

const columnOptions = {
  fieldKind: "sub" as const,
  optionKey: (o: Column) => `${o.type}-${o.value}`,
  optionLabel: (o: Column) =>
    o.type === "empty" ? (
      <span className="text-gray-500">Leave Empty</span>
    ) : (
      <>
        <span className="text-gray-500">{typeLabel[o.type]}: </span>
        <span className="font-semibold">{o.value}</span>
      </>
    ),
};

export const GaugeUploadFile = ({
  form,
  gauges,
  validators,
}: UploadGaugesContentProps) => (
  <>
    <form.Field
      name="upload"
      validators={{
        ...validators.upload,
        onChangeAsync: async ({ value, fieldApi }) => {
          try {
            if (value) {
              const workbook = await loadWorkbook(value);
              fieldApi.form.setFieldValue("workbook", workbook);
              fieldApi.form.setFieldValue("sheet", "");
              await fieldApi.form.validateField("sheet", "change");
              if (workbook.sheets.length === 0) {
                throw new Error("No sheets found in workbook");
              } else if (workbook.sheets.length === 1) {
                fieldApi.form.setFieldValue("sheet", workbook.sheets[0]!);
              }
            } else {
              fieldApi.form.setFieldValue("workbook", null);
              fieldApi.form.setFieldValue("sheet", "");
            }
            await fieldApi.form.validateField("sheet", "change");

            return undefined;
          } catch (e) {
            return e instanceof Error ? e.message : "Invalid file selected";
          }
        },
      }}
      children={(field) => (
        <TextField
          field={field}
          label="Select a file"
          type="file"
          accept=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      )}
    />
    <form.Subscribe
      selector={(state) => state.values.workbook?.sheets}
      children={(sheets) => (
        <form.Field
          name="sheet"
          validators={{
            ...validators.sheet,
            onChangeAsync: async ({ value, fieldApi }) => {
              if (value) {
                const sheet = fieldApi.form.state.values.workbook?.data[value];
                if (!sheet) return "Sheet not found";

                if (value !== fieldApi.form.state.values.mappedSheet) {
                  fieldApi.form.setFieldValue("columns", {
                    id:
                      sheet.columns.find((c) =>
                        c.value.toLocaleLowerCase().includes("imei"),
                      ) ||
                      sheet.columns.find(
                        (c) => c.value.toLocaleLowerCase() === "id",
                      ) ||
                      null,
                    name: sheet.columns.find((c) =>
                      c.value.toLocaleLowerCase().includes("name"),
                    ) || { type: "empty", value: "" },
                    product:
                      sheet.columns.find((c) =>
                        c.value.toLocaleLowerCase().includes("product"),
                      ) || null,
                    technology:
                      sheet.columns.find((c) =>
                        c.value.toLocaleLowerCase().includes("technology"),
                      ) || null,
                    model: sheet.columns.find((c) =>
                      c.value.toLocaleLowerCase().includes("model"),
                    ) || { type: "empty", value: "" },
                  });
                }
              } else {
                fieldApi.form.setFieldValue(
                  "columns",
                  defaultUploadGaugeFields.columns,
                );
              }

              fieldApi.form.setFieldValue("mappedSheet", value);
              return undefined;
            },
          }}
          children={(field) => (
            <ListField
              field={field}
              label="Select a sheet"
              empty="No sheets available"
              options={sheets || []}
            />
          )}
        />
      )}
    />
    <form.Subscribe
      selector={(state) => state.values.workbook?.data[state.values.sheet]}
      children={(sheet) =>
        sheet ? (
          <div className={fieldParts.group}>
            <form.Field
              name="columns.id"
              validators={validators.column}
              children={(field) => (
                <ListField
                  field={field}
                  label="IMEI"
                  options={sheet?.columns || []}
                  {...columnOptions}
                />
              )}
            />
            <form.Field
              name="columns.name"
              validators={validators.column}
              children={(field) => (
                <ListField
                  field={field}
                  label="Name"
                  options={[
                    { type: "empty", value: "" },
                    ...(sheet?.columns || []),
                  ]}
                  {...columnOptions}
                />
              )}
            />
            <form.Field
              name="columns.product"
              validators={validators.column}
              children={(field) => (
                <ListField
                  field={field}
                  label="Product"
                  options={[
                    ...(sheet?.columns || []),
                    { type: "static", value: "Cippus" },
                    { type: "static", value: "Tekelek" },
                  ]}
                  {...columnOptions}
                />
              )}
            />
            <form.Field
              name="columns.technology"
              validators={validators.column}
              children={(field) => (
                <ListField
                  field={field}
                  label="Technology"
                  options={[
                    ...(sheet?.columns || []),
                    { type: "static", value: "ADC" },
                    { type: "static", value: "Pressure" },
                    { type: "static", value: "Ultrasonic" },
                  ]}
                  {...columnOptions}
                />
              )}
            />
            <form.Field
              name="columns.model"
              validators={validators.column}
              children={(field) => (
                <ListField
                  field={field}
                  label="Model"
                  options={[
                    { type: "empty", value: "" },
                    ...(sheet?.columns || []),
                    ...unique(
                      (gauges.data || [])
                        .filter(isModelGauge)
                        .map((g) => g.model),
                    ).map((value) => ({ type: "static" as const, value })),
                  ]}
                  {...columnOptions}
                />
              )}
            />
          </div>
        ) : null
      }
    />
  </>
);
