import { TextField } from "../../components";
import { GaugeConfigContentProps } from "../../data";

export const TekUltrasonicContent = ({
  form,
  validators,
}: GaugeConfigContentProps) => (
  <>
    <form.Field
      name="mountToBottom"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Mount to Bottom" inputMode="decimal" />
      )}
    />
    <form.Field
      name="offsetVolume"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Volume Offset" inputMode="decimal" />
      )}
    />
  </>
);
