import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { CommentDeleteContent } from "../comments";
import { GaugeConfigContent } from "./config";
import { GaugeDeleteContent } from "./delete";
import { GaugeDisableContent } from "./disable";
import { GaugeEditContent } from "./edit";
import { GaugeScheduleContent } from "./schedule";
import { GaugeSonicQualityContent } from "./sonic";
import { GaugeStrappingContent } from "./strapping";

export const GaugeActionPanel = () => {
  const { Modal, Slide, props } = useMatch({
    from: "/_authenticated/gauges/$gaugeId",
    select: (s) => {
      const props = {
        gaugeId: s.params.gaugeId,
        commentId: s.search.comment,
        entityId: s.params.gaugeId,
        entityType: "gauge" as const,
      };

      switch (s.search.action) {
        case "delete":
          if (s.search.comment) return { Modal: CommentDeleteContent, props };
          return { Modal: GaugeDeleteContent, props };
        case "disable":
          return { Modal: GaugeDisableContent, props };
        case "config":
          return { Slide: GaugeConfigContent, props };
        case "edit":
          return { Slide: GaugeEditContent, props };
        case "strapping":
          return { Slide: GaugeStrappingContent, props };
        case "schedule":
          return { Slide: GaugeScheduleContent, props };
        case "sonic":
          return { Slide: GaugeSonicQualityContent, props };
      }

      return { props };
    },
  });

  return (
    <Actions
      modal={Modal && <Modal {...props} />}
      slide={Slide && <Slide {...props} />}
    />
  );
};
