import { ReactNode } from "react";

export type EmptyTableProps = {
  icon: (props: { className: string; stroke: number }) => ReactNode;
  title: string;
  description?: ReactNode;
};

export const EmptyTable = ({
  icon: Icon,
  title,
  description,
}: EmptyTableProps) => (
  <>
    <Icon className="size-12 text-gray-400" stroke={1.6} />
    <p className="mt-2 text-sm font-semibold italic text-gray-500">{title}</p>
    {description && <p className="mt-1 text-sm text-gray-500">{description}</p>}
  </>
);
