import {
  CheckInputField,
  Field,
  Label,
  SlideContent,
  fieldParts,
} from "../../components";
import { permissionFields, useAuth, useUpdateRole, userName } from "../../data";

export const RoleEditContent = ({ roleId }: { roleId: string }) => {
  const { data, error, form } = useUpdateRole(roleId);
  const { email } = useAuth();
  const selfError =
    data.user?.email === email
      ? new Error("You cannot update your own access.")
      : undefined;

  let entityName = "";
  switch (data.entityType) {
    case "account":
      entityName = data.account?.name || "";
      break;
    case "customer":
      entityName = data.customer?.name || "";
      break;
  }

  return (
    <SlideContent
      title={`Update permissions for ${data.entityType}`}
      description={`Set the permissions for ${userName(data.user)}'s access to ${entityName}`}
      error={error || selfError}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        {
          text: "Update",
          type: "submit",
          variant: "action",
          disabled: !!selfError,
        },
      ]}
    >
      <Field>
        <Label>Permissions</Label>
        <div className={fieldParts.checks}>
          {permissionFields.map(({ name, validators, label, description }) => (
            <form.Field
              key={name}
              name={name}
              validators={validators}
              children={(field) => (
                <CheckInputField
                  field={field}
                  label={label}
                  description={description(data.entityType)}
                />
              )}
            />
          ))}
        </div>
      </Field>
    </SlideContent>
  );
};
