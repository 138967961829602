import { Clerk } from "@clerk/clerk-js/headless";
import Clarity from "@microsoft/clarity";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { z } from "zod";

import { TeamPermission, hasTeamPermission } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { config } from "../config";

let tokenGetter: () => Promise<string | null | undefined> = () =>
  Promise.resolve(undefined);

export const getAuthToken = () => tokenGetter();

export type AuthContext = {
  client: Clerk;
  isAuthenticated: boolean;
  email: string | undefined;
  hasTeamPermission: (needed: TeamPermission) => boolean;
};

export const useSetupAuth = () => {
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState<AuthContext>();

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const timeout = setTimeout(() => {
      setLoading(true);
    }, 1000);

    (async () => {
      const client = new Clerk(config.authId, {
        proxyUrl: is_local ? "/auth" : undefined,
      });
      await client.load();

      if (client.session) tokenGetter = client.session.getToken;

      if (client.user) {
        Clarity.identify(client.user.id, client.session?.id);

        if (client.user.primaryEmailAddress)
          Clarity.setTag(
            "email",
            client.user.primaryEmailAddress?.emailAddress,
          );
        if (client.user.publicMetadata.jlteam)
          Clarity.setTag("jlteam", client.user.publicMetadata.jlteam);
      }

      setContext({
        client,
        isAuthenticated: !!client.user,
        email: client.user?.primaryEmailAddress?.emailAddress,
        hasTeamPermission: hasTeamPermission(
          client.user?.publicMetadata.jlteam,
        ),
      });
    })()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        clearTimeout(timeout);
        setTimeout(() => setLoading(false), 700);
      });
  }, []);

  return { loading, context };
};

const authContext = createContext<AuthContext>({
  client: null!,
  isAuthenticated: false,
  email: undefined,
  hasTeamPermission: () => false,
});

export const AuthProvider = authContext.Provider;

export const useAuth = () => useContext(authContext);

const profileValidation = {
  firstName: z.string().min(1, "Please enter your first name"),
  lastName: z.string().min(1, "Please enter your last name"),
};

export const useUpdateProfile = () => {
  const {
    client: { user },
  } = useAuth();

  const form = useForm({
    defaultValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.unsafeMetadata.phone || "",
      company: user?.unsafeMetadata.company || "",
      title: user?.unsafeMetadata.title || "",
    },
    onSubmit: async ({ value }) => {
      await user?.update({
        firstName: value.firstName,
        lastName: value.lastName,
        unsafeMetadata: {
          phone: value.phone,
          company: value.company,
          title: value.title,
        },
      });
    },
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(
    profileValidation,
    form.state.submissionAttempts,
  );

  return { form, validators, user };
};
