import { Input, InputProps } from "@headlessui/react";
import { IconExclamationCircle } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode } from "react";

import { tw } from "../../assets";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";

export const inputParts = {
  wrapped: tw`block w-full flex-1 border-0 bg-transparent px-0 py-2 focus:outline-none focus:ring-0`,
  file: tw`file:mr-4 file:rounded-full file:border-0 file:px-4 file:py-1.5 file:text-sm file:font-semibold file:leading-none file:tracking-wide`,
  icon: tw`pointer-events-none size-5 text-gray-500`,
  addon: tw`p-1 text-xs font-bold tracking-wide text-gray-500`,
};

export const inputKinds = {
  standard: tw`flex flex-1 flex-wrap items-center gap-x-2 rounded-md border-0 px-3 shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset`,
  simple: tw`flex flex-1 flex-wrap items-center gap-x-2 py-0.5`,
};

export const inputVariants = {
  action: tw`bg-white ring-gray-300 placeholder:text-gray-400 focus-within:ring-sky-600`,
  error: tw`ring-red-800`,
};

export type TextInputProps = {
  className?: string;
  icon?: (props: { className?: string }) => ReactNode;
  addon?: string;
  kind?: keyof typeof inputKinds;
  variant?: keyof typeof inputVariants;
};

export const TextInput = ({
  className,
  icon: Icon,
  addon,
  kind = "standard",
  variant = "action",
  ...props
}: TextInputProps & InputProps) => (
  <label
    className={clsx(
      inputKinds[kind],
      inputVariants[variant],
      props.invalid && inputVariants.error,
      className,
    )}
  >
    {Icon && <Icon className={inputParts.icon} />}
    {addon && <div className={inputParts.addon}>{addon}</div>}
    <Input
      className={clsx(
        inputParts.wrapped,
        props.type === "file" && inputParts.file,
      )}
      {...props}
    />
    {props.invalid && (
      <IconExclamationCircle
        className={clsx(inputParts.icon, "text-red-700")}
      />
    )}
  </label>
);

export const TextInputField = ({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, any>;
} & InputProps &
  TextInputProps) => (
  <TextInput
    id={field.name}
    name={field.name}
    value={props.type === "file" ? undefined : field.state.value}
    onBlur={field.handleBlur}
    onChange={(e) =>
      props.type === "file"
        ? field.handleChange(e.target.files?.[0])
        : field.handleChange(e.target.value)
    }
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const TextField = ({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, any>;
  label: ReactNode;
  fieldKind?: keyof typeof fieldKinds;
} & TextInputProps &
  InputProps) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <TextInputField field={field} {...rest} />
    <FieldError field={field} />
  </Field>
);
