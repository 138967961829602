import { Transition } from "@headlessui/react";
import { IconExclamationCircle } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { useDisplayError } from "../../data";
import { transitions } from "../transitions";
import { Label, labelKinds } from "./parts";

export const FormError = ({ error }: { error?: Error | null }) => {
  const { message, clear } = useDisplayError(error);

  return (
    <Transition show={!!error} afterLeave={clear}>
      <div
        className={clsx(
          "flex min-w-0 items-center justify-start gap-2",
          transitions.grow,
        )}
      >
        <IconExclamationCircle className="text-red-700" />
        <p className={labelKinds.error} title={message}>
          {message}
        </p>
      </div>
    </Transition>
  );
};

export const FieldError = ({
  field,
}: {
  field: FieldApi<any, any, any, any, any>;
}) => {
  const [displayError, setDisplayError] = useState(field.state.meta.errors[0]);
  useEffect(() => {
    if (field.state.meta.errors[0]) setDisplayError(field.state.meta.errors[0]);
  }, [field.state.meta.errors[0]]);

  return (
    <Transition
      show={field.state.meta.errors.length > 0}
      afterLeave={() => setDisplayError(undefined)}
    >
      <Label htmlFor={field.name} kind="error" className={transitions.grow}>
        {displayError}
      </Label>
    </Transition>
  );
};
