import { TextField } from "../../components";
import { AccountConfigContentProps } from "../../data";

export const ColibriContent = ({
  form,
  validators,
}: AccountConfigContentProps) => (
  <>
    <form.Field
      name="url"
      validators={validators.url}
      children={(field) => (
        <TextField field={field} label="Colibri URL" autoFocus />
      )}
    />
  </>
);
