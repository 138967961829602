import {
  IconActivity,
  IconBasket,
  IconBell,
  IconBox,
  IconCloudDownload,
  IconDropletHalf2Filled,
  IconHistory,
  IconMapPin,
  IconSquareRoundedPlus,
  IconTruckDelivery,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";

import { mapOrEmpty } from "@joy/shared-utils";

import { specialChars } from "../../assets";
import {
  Entity,
  EntityCreated,
  EntityUpdated,
  ExternalLink,
  LinkButton,
  Time,
  pageParts,
} from "../../components";
import {
  account,
  displayPercent,
  gauge,
  tankQuery,
  tankUnitOptions,
  useAuth,
} from "../../data";
import { CommentsPanel } from "../comments";

export const TankInfoPanel = ({ tankId }: { tankId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(tankQuery(tankId));

  return (
    <div className={pageParts.page}>
      <Entity
        title="Tank Info"
        edit={
          data.permissions.includes("write")
            ? {
                to: "/tanks/$tankId",
                params: { tankId },
                search: { action: "edit" },
                replace: true,
              }
            : undefined
        }
        item={data}
        details={[
          [
            [IconBox, "Name", (c) => c.name],
            [
              IconDropletHalf2Filled,
              "Contents",
              (c) =>
                [c.unit && tankUnitOptions[c.unit], c.product?.label]
                  .filter((i) => !!i)
                  .join(" of "),
            ],
            [
              IconMapPin,
              "Location",
              (c) =>
                c.location && (
                  <ExternalLink
                    kind="link"
                    variant="standard"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${c.location.latitude},${c.location.longitude}`}
                    text={c.location.display}
                  />
                ),
            ],
          ],
          [
            data.gauge
              ? [
                  gauge.icon,
                  "Gauge",
                  (c) => (
                    <LinkButton
                      kind="link"
                      variant="standard"
                      to="/gauges/$gaugeId"
                      params={{ gaugeId: c.gauge?.id || "" }}
                      text={c.gauge?.name || ""}
                    />
                  ),
                ]
              : undefined,
            [
              account.icon,
              "Account",
              (c) => (
                <LinkButton
                  kind="link"
                  variant="standard"
                  to="/accounts/$accountId"
                  params={{ accountId: c.account.id }}
                  text={c.account.name}
                />
              ),
            ],
            [IconBasket, "Product", (c) => c.account.product],
            [
              IconActivity,
              "Activated at",
              (c) =>
                c.activatedAt ? (
                  <Time date={c.activatedAt} prefix="Activated" />
                ) : (
                  "Inactive"
                ),
            ],
          ],
          [
            [IconSquareRoundedPlus, "Created", EntityCreated],
            [IconHistory, "Updated", EntityUpdated],
          ],
        ]}
        actions={[
          {
            kind: "link",
            variant: "action",
            to: "/tanks/$tankId",
            search: { action: "download" },
            replace: true,
            icon: IconCloudDownload,
            text: "Download History",
          },
        ]}
      />
      <Entity
        title="Alerts"
        edit={
          data.permissions.includes("write")
            ? {
                to: "/tanks/$tankId",
                params: { tankId },
                search: { action: "alerts" },
                replace: true,
              }
            : undefined
        }
        item={data}
        details={[
          [
            [
              IconBell,
              "Alert Levels",
              (c) =>
                mapOrEmpty(
                  c.alertLevels,
                  (a, idx) => (
                    <p key={idx} className="mb-1">
                      Volume {a.polarity} {displayPercent(a.threshold)}
                    </p>
                  ),
                  specialChars.endash,
                ),
            ],
            [
              IconTruckDelivery,
              "Alert Rates",
              (c) =>
                mapOrEmpty(
                  c.alertRates,
                  (a, idx) => (
                    <p key={idx} className="mb-1">
                      Volume {a.polarity === "rising" ? "rises" : "falls"} by{" "}
                      {displayPercent(a.threshold)} over {a.period} minutes
                    </p>
                  ),
                  specialChars.endash,
                ),
            ],
          ],
        ]}
      />
      {hasTeamPermission("admin") && (
        <CommentsPanel
          entityType="tank"
          entityId={tankId}
          onDelete={{
            to: "/tanks/$tankId",
            params: { tankId },
          }}
        />
      )}
    </div>
  );
};
