import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import { GraphQLError, GraphQLErrorExtensions } from "graphql";
import ky from "ky";

import { getAuthToken } from "../auth";

export const api = ky.create({
  prefixUrl: `/api`,
  retry: 0,
  hooks: {
    beforeRequest: [
      async (request) => {
        const authToken = await getAuthToken();
        if (authToken)
          request.headers.set("Authorization", `Bearer ${authToken}`);
      },
    ],
  },
});

export const request = async <R, V>(
  document: DocumentNode<R, V>,
  variables?: V,
): Promise<R> => {
  const response = await api.post("graphql", {
    json: { query: document, variables },
    throwHttpErrors: false,
  });

  const { data, message, errors } = (await response.json()) as {
    data: R;
    message?: string;
    errors?: { message: string; extensions?: GraphQLErrorExtensions }[];
  };

  if (response.status === 500 && message) {
    throw new GraphQLError(message);
  }

  if (errors?.[0])
    throw new GraphQLError(errors[0].message, {
      extensions: errors[0].extensions,
    });

  return data;
};

export const requestFn =
  <R extends object, V extends object>(
    document: DocumentNode<R, V>,
  ): ((variables: V) => Promise<R>) =>
  (variables) =>
    request(document, variables);
