import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { clsx } from "clsx";
import { z } from "zod";

import {
  Button,
  ListInput,
  StatsHeader,
  TablePage,
  pageParts,
} from "../../../components";
import {
  calendarToString,
  defaultCalendar,
  invoice,
  invoicesQuery,
  listLoader,
  useInvoicesCalendar,
} from "../../../data";
import { useTable } from "../../../hooks";

const validateSearch = z.object({
  action: z.enum(["generate"]).optional(),
});

export const Route = createFileRoute("/_super/invoices/")({
  validateSearch,
  loader: async ({ context }) =>
    listLoader(
      context.queryClient,
      invoicesQuery(calendarToString(defaultCalendar)),
    ),
  component: Component,
});

export function Component() {
  const { calendar, month, year, adjust } = useInvoicesCalendar();
  const invoices = useTable(invoicesQuery(calendar), {
    columnDefs: (c) => [
      c.accessor("number", {
        header: "Invoice",
      }),
    ],
    select: (invoiceId) => ({
      to: "/invoices/$invoiceId",
      params: { invoiceId },
    }),
    create: {
      to: "/invoices",
      search: { action: "generate" },
      replace: true,
      text: "Generate Invoices",
    },
    word: invoice,
  });

  return (
    <TablePage
      {...invoices}
      actions={
        <div className={clsx(pageParts.actionItems, "justify-end")}>
          <Button
            icon={IconChevronLeft}
            kind="menu"
            variant="standard"
            onClick={() => adjust(-1)}
          />
          <div>
            <ListInput {...month} />
          </div>
          <div>
            <ListInput {...year} />
          </div>
          <Button
            icon={IconChevronRight}
            kind="menu"
            variant="standard"
            onClick={() => adjust(1)}
          />
        </div>
      }
    >
      <StatsHeader stats={invoices.stats} />
    </TablePage>
  );
}
