import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { AccountCreateContent } from "./create";

export const AccountsActionPanel = () => {
  const { Slide } = useMatch({
    from: "/_authenticated/accounts/",
    select: (s) => {
      switch (s.search.action) {
        case "create":
          return { Slide: AccountCreateContent };
      }

      return {};
    },
  });

  return <Actions slide={Slide && <Slide />} />;
};
