import { DependencyList, useEffect, useState } from "react";

export const useDebounce = <V>(
  fn: () => V,
  delay: number,
  deps: DependencyList,
) => {
  const [value, setValue] = useState(fn);

  useEffect(() => {
    const timer = setTimeout(() => {
      setValue(fn);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, deps);

  return value;
};
