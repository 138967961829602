import { IconArrowNarrowRight } from "@tabler/icons-react";

import { tw } from "../../assets";
import { linkVariants } from "../button";

export const actionCell = () => ({
  id: "actions",
  meta: { className: tw`w-0` },
  cell: () => <IconArrowNarrowRight className={linkVariants.action} />,
});
