import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { useDeleteDocument } from "../../data";

export const DocumentDeleteContent = ({
  documentId,
}: {
  documentId: string;
}) => {
  const { data, error, isPending, onDelete } = useDeleteDocument(documentId);

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete "${data.title}"?`}
      description={`Are you sure you want to delete "${data.title}"? This action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Deleting..." }}
      buttons={[
        { text: "Delete", type: "submit", variant: "danger", autoFocus: true },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
