import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";

import { transitions } from "../transitions";

export const FormSuccess = ({
  icon: Icon,
  className = "text-emerald-600",
  text,
  hide,
}: {
  icon: (props: { className?: string }) => ReactNode;
  className?: string;
  text?: string;
  hide?: boolean;
}) => (
  <Transition show={!!text && !hide}>
    <p
      className={clsx(
        "flex items-center gap-2 text-sm font-semibold",
        transitions.grow,
        className,
      )}
    >
      <Icon className="flex-none" />
      {text}
    </p>
  </Transition>
);
