export const dayOrder = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

export type Day = (typeof dayOrder)[number];

export const dayAbbreviation: Record<Day, string> = {
  Sunday: "Sun",
  Monday: "Mon",
  Tuesday: "Tue",
  Wednesday: "Wed",
  Thursday: "Thu",
  Friday: "Fri",
  Saturday: "Sat",
};

export const displayDays = (days: Record<Day, boolean>) => {
  const selected = dayOrder.map((day) => days[day]);
  const weekDays = selected.slice(1, 6);

  if (selected.every((day) => day)) return "every day";
  if (weekDays.every((day) => day) && !days.Saturday && !days.Sunday)
    return "weekdays";
  if (!weekDays.some((day) => day) && days.Saturday && days.Sunday)
    return "weekends";

  const firstIndex = selected.indexOf(true);
  const lastIndex = selected.lastIndexOf(true);
  if (
    lastIndex - firstIndex > 0 &&
    selected.slice(firstIndex, lastIndex + 1).every((day) => day)
  ) {
    return `${dayOrder[firstIndex]!} - ${dayOrder[lastIndex]!}`;
  }

  return dayOrder.filter((day) => days[day]).join(", ");
};
