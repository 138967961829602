import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import {
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  stackCell,
} from "../../../../components";
import {
  document,
  documentsQuery,
  listLoader,
  useAuth,
  userName,
} from "../../../../data";
import { useTable } from "../../../../hooks";
import { DocumentsActionPanel } from "../../../../panels";

const validateSearch = z.object({
  action: z.enum(["create"]).optional(),
});

export const Route = createFileRoute("/_admin/library/documents/")({
  validateSearch,
  loader: ({ context }) => listLoader(context.queryClient, documentsQuery()),
  component: Component,
});

export function Component() {
  const { hasTeamPermission } = useAuth();
  const documents = useTable(documentsQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "title",
        stackCell({
          header: "Title",
          detail: () => [],
        }),
      ),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(actionCell()),
    ],
    select: (documentId) => ({
      to: "/library/documents/$documentId",
      params: { documentId },
    }),
    create: hasTeamPermission("super")
      ? {
          to: "/library/documents",
          search: { action: "create" },
          replace: true,
        }
      : undefined,
    word: document,
  });

  return (
    <TablePage {...documents}>
      <StatsHeader stats={documents.stats} />
      <DocumentsActionPanel />
    </TablePage>
  );
}
