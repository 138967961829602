import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createRouter } from "@tanstack/react-router";

import "../assets/Inter/inter.css";
import { ErrorComponent, Loading, PendingComponent } from "../components";
import { AuthProvider, useSetupAuth } from "../data";
import { FeedbackProvider } from "../hooks";
import "./app.css";
import { routeTree } from "./route-tree.generated";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
    },
  },
});

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    auth: null!,
  },
  defaultErrorComponent: ErrorComponent,
  defaultPendingComponent: PendingComponent,
  defaultPreloadStaleTime: 0,
});
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  const { context, loading } = useSetupAuth();

  return (
    <>
      {context && (
        <AuthProvider value={context}>
          <QueryClientProvider client={queryClient}>
            <FeedbackProvider>
              <RouterProvider
                router={router}
                context={{ queryClient, auth: context }}
              />
            </FeedbackProvider>
          </QueryClientProvider>
        </AuthProvider>
      )}
      <Loading show={loading} />
    </>
  );
}
