import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { useDeleteGauge } from "../../data";

export const GaugeDeleteContent = ({ gaugeId }: { gaugeId: string }) => {
  const { data, error, isPending, onDelete } = useDeleteGauge(gaugeId);

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete "${data.name}"?`}
      description={`Are you sure you want to delete "${data.name}"? This action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Deleting..." }}
      buttons={[
        { text: "Delete", type: "submit", variant: "danger", autoFocus: true },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
