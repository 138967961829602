import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import { customer, useAuth, usePrefetchRole, userRolesQuery } from "../../data";
import { useTable } from "../../hooks";
import { roleColumns } from "../role";

export const UserCustomersPanel = ({ userId }: { userId: string }) => {
  const { hasTeamPermission } = useAuth();
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const roles = useTable(userRolesQuery(userId, "customer"), {
    columnDefs: roleColumns({
      prefetchRole,
      hasTeamPermission,
      navigate,
      display: "customer",
      current: { to: "/users/$userId" },
      permissions: ["read", "write", "install", "admin"],
    }),
    create: {
      to: "/users/$userId",
      search: { action: "add", entity: "customer" },
      replace: true,
    },
    word: customer,
  });

  return <TablePage {...roles} />;
};
