import {
  IconBriefcase,
  IconHistory,
  IconSquareRoundedPlus,
  IconTrashX,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";

import { Entity, EntityCreated, EntityUpdated } from "../../components";
import { customerQuery, useAuth } from "../../data";

export const CustomerInfoPanel = ({ customerId }: { customerId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(customerQuery(customerId));

  return (
    <Entity
      title="Customer Info"
      edit={
        hasTeamPermission("super")
          ? {
              to: "/customers/$customerId",
              params: { customerId },
              search: { action: "edit" },
              replace: true,
            }
          : undefined
      }
      item={data}
      details={[
        [[IconBriefcase, "Name", (c) => c.name]],
        [
          [IconSquareRoundedPlus, "Created at", EntityCreated],
          [IconHistory, "Updated", EntityUpdated],
        ],
      ]}
      actions={[
        hasTeamPermission("super")
          ? {
              kind: "link",
              variant: "danger",
              search: { action: "delete" },
              replace: true,
              icon: IconTrashX,
              text: "Delete Customer",
            }
          : undefined,
      ]}
    />
  );
};
