import {
  IconBattery1,
  IconBattery2,
  IconBattery3,
  IconBattery4,
} from "@tabler/icons-react";

export const BatteryDisplay = ({
  className,
  percent,
}: {
  className?: string;
  percent: number;
}) => {
  let Icon = IconBattery1;
  if (percent >= 90) Icon = IconBattery4;
  else if (percent >= 60) Icon = IconBattery3;
  else if (percent >= 40) Icon = IconBattery2;

  return <Icon className={className} />;
};

export const displayBattery = (
  percent: number | null | undefined,
  className?: string,
) => {
  if (typeof percent !== "number") return undefined;
  return <BatteryDisplay percent={percent} className={className} />;
};
