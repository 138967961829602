import { CellContext } from "@tanstack/react-table";

import { CheckInput } from "../forms";
import { cellKinds } from "./parts";

export const CellCheck = ({ value }: { value: boolean }) => (
  <CheckInput className="pl-4" checked={value} />
);

export const checkCell = ({ header }: { header: string }) => ({
  header,
  id: header,
  meta: { className: cellKinds.appearsxl },
  cell: (v: CellContext<any, boolean>) => <CellCheck value={v.getValue()} />,
});
