export type Range = { min: number; max: number };

export const convertRange = (
  input: number,
  { rangeIn, rangeOut }: { rangeIn: Range; rangeOut: Range },
) => {
  const ratio = (rangeOut.max - rangeOut.min) / (rangeIn.max - rangeIn.min);
  const portion = (input - rangeIn.min) * ratio;
  return rangeOut.min + portion;
};
