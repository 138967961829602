import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { ReactNode, Suspense, createContext, useContext } from "react";

import { Button, ButtonProps } from "./button";
import { Form, FormError } from "./forms";
import { Loading } from "./navigation";
import { transitions } from "./transitions";

export type SlideContextProps = {
  show: boolean;
  onClose: () => void;
};

export const SlideContext = createContext<SlideContextProps>({
  show: true,
  onClose: () => {},
});

export type SlideProps = SlideContextProps & {
  children: ReactNode;
};

export const Slide = ({ children, show, onClose }: SlideProps) => (
  <SlideContext.Provider value={{ show, onClose }}>
    <Dialog className="relative z-10" open={show} onClose={onClose}>
      <DialogBackdrop
        transition
        className={clsx("fixed inset-0 bg-gray-500/75", transitions.fade)}
      />
      <Suspense>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-12 sm:pl-16">
              {children}
            </div>
          </div>
        </div>
      </Suspense>
    </Dialog>
  </SlideContext.Provider>
);

export type SlideContentProps = {
  children: ReactNode;
  title?: string;
  description?: ReactNode;
  error?: Error | null;
  onSubmit?: () => void;
  loading?: { show: boolean; text: string };
  buttons?: ButtonProps[];
};

export const SlideContent = ({
  children,
  title,
  description,
  error,
  onSubmit,
  loading,
  buttons,
}: SlideContentProps) => {
  const { onClose } = useContext(SlideContext);
  return (
    <DialogPanel
      transition
      className={clsx(
        "pointer-events-auto w-screen min-w-0 max-w-prose bg-white shadow-2xl",
        transitions.slide,
      )}
    >
      <Form kind="full" onSubmit={onSubmit} onReset={onClose}>
        <div className="flex-1">
          <div className="bg-slate-100/80 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                  {title}
                </DialogTitle>
                <Description className="text-sm leading-6 text-gray-500">
                  {description}
                </Description>
                <FormError error={error} />
              </div>
              <div className="flex h-7 items-center">
                <Button
                  type="reset"
                  icon={IconX}
                  kind="link"
                  variant="cancel"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 px-4 py-6 sm:px-6">
            {children}
          </div>
        </div>
        {!!buttons?.length && (
          <div className="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="flex justify-end space-x-3">
              {buttons?.map((props) => (
                <Button
                  key={props.text}
                  {...props}
                  disabled={props.disabled || loading?.show}
                />
              ))}
            </div>
          </div>
        )}
      </Form>
      {loading && <Loading {...loading} />}
    </DialogPanel>
  );
};
