import { FC } from "react";

import { SlideContent } from "../../components";
import {
  AccountConfigContentProps,
  AccountProduct,
  useUpdateAccountConfig,
} from "../../data";
import { ColibriContent } from "./config.colibri";
import { JoyGaugeContent } from "./config.joygauge";
import { ProGaugeContent } from "./config.progauge";
import { SmartFillContent } from "./config.smartfill";

const configContent: Record<AccountProduct, FC<AccountConfigContentProps>> = {
  Colibri: ColibriContent,
  JoyGauge: JoyGaugeContent,
  ProGauge: ProGaugeContent,
  RMSDelta: () => <></>,
  SmartFill: SmartFillContent,
};

export const AccountConfigContent = ({ accountId }: { accountId: string }) => {
  const { data, error, form, validators } = useUpdateAccountConfig(accountId);

  const Fields = configContent[data.product];

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the account information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <Fields data={data} form={form} validators={validators} />
    </SlideContent>
  );
};
