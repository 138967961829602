import { format } from "date-fns";

import { downloadFile } from "../download.js";
import { NestedObjectKeys, getValue } from "../get.js";

export const wrapCell = (
  value: unknown,
  formatter?: (value: unknown) => string,
) => {
  if (value === undefined || value === null) return "";
  if (formatter) return formatter(value);

  const stringified = JSON.stringify(value);
  return stringified.replaceAll('\\"', '""');
};

const formatters = {
  date: (value: unknown) => {
    if (
      value instanceof Date ||
      typeof value === "string" ||
      typeof value === "number"
    )
      return format(value, "yyyy-MM-dd");
    return "";
  },
  dateTime: (value: unknown) => {
    if (
      value instanceof Date ||
      typeof value === "string" ||
      typeof value === "number"
    )
      return format(value, "yyyy-MM-dd HH:mm:ss");
    return "";
  },
};

export type ToCSVOptions<
  I extends object,
  K extends NestedObjectKeys<I> = NestedObjectKeys<I>,
> = {
  cells: K[];
  headers: Record<K, string>;
  formats?: Partial<Record<K, keyof typeof formatters>>;
  visibility?: Partial<Record<K, boolean>>;
};

export const toCSV = <
  I extends object,
  K extends NestedObjectKeys<I> = NestedObjectKeys<I>,
>(
  items: I[],
  { cells, headers, visibility, formats }: ToCSVOptions<I, K>,
): string => {
  const visibleCells = cells.filter((c) => visibility?.[c] !== false);

  const rows = [
    visibleCells.map((cell) => wrapCell(headers[cell])),
    ...(items || []).map((row) =>
      visibleCells.map((cell) =>
        wrapCell(
          getValue(row, cell),
          formats?.[cell] ? formatters[formats[cell]] : undefined,
        ),
      ),
    ),
  ];
  return rows.map((r) => r.join(",")).join("\r\n");
};

export const downloadCSV = <I extends object, K extends NestedObjectKeys<I>>(
  items: I[],
  { file, ...options }: ToCSVOptions<I, K> & { file: string },
) => {
  const asBlob = new Blob([toCSV(items, options)], { type: "text/csv" });
  downloadFile(asBlob, `${file}.csv`);
};
