import { SlideContent, TextField } from "../../components";
import { ColourField } from "../../components/forms/colour";
import { useUpdateProduct } from "../../data";

export const ProductEditContent = ({
  customerId,
  productId,
}: {
  customerId?: string;
  productId: string;
}) => {
  const { data, error, form, validators } = useUpdateProduct(
    productId,
    customerId,
  );

  return (
    <SlideContent
      title={`Update ${data.product?.label}`}
      description="Update and save the product information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="label"
        validators={validators.label}
        children={(field) => (
          <TextField field={field} label="Label" autoFocus />
        )}
      />
      <form.Field
        name="colour"
        validators={validators.colour}
        children={(field) => <ColourField field={field} label="Colour" />}
      />
    </SlideContent>
  );
};
