export const toTitle = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLocaleLowerCase()}`;

export const pluralize = (
  count: number,
  single: string,
  plural: string = `${single}s`,
) => {
  if (count === 1) return single;
  return `${count} ${plural}`;
};
