import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { GaugeUploadContent } from "./upload";

export const GaugesActionPanel = () => {
  const { Slide } = useMatch({
    from: "/_authenticated/gauges/",
    select: (s) => {
      switch (s.search.action) {
        case "upload":
          return { Slide: GaugeUploadContent };
      }

      return {};
    },
  });

  return <Actions slide={Slide && <Slide />} />;
};
