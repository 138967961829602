import { lazy } from "react";

export const Editor = lazy(() =>
  import("./editor.lazy").then(({ Editor }) => ({ default: Editor })),
);

export const EditorDisplay = lazy(() =>
  import("./editor.lazy").then(({ EditorDisplay }) => ({
    default: EditorDisplay,
  })),
);

export const convertLexical = async (
  content: string | undefined | null,
  format: "html" | "text",
) => {
  const { convertLexicalToHtml, convertLexicalToText } = await import(
    "./editor.lazy"
  );

  switch (format) {
    case "html":
      return convertLexicalToHtml(content);
    case "text":
      return convertLexicalToText(content);
  }
};
