import { IconMessageReport } from "@tabler/icons-react";

import {
  EditorField,
  ListField,
  ModalContent,
  TextField,
} from "../../components";
import { useSendFeedback } from "../../data";

export const FeedbackContent = () => {
  const { error, form, validators, isAuthenticated } = useSendFeedback();

  return (
    <ModalContent
      icon={IconMessageReport}
      variant="action"
      title="How can we help?"
      description="Let us know what you need and we'll reach out as soon as possible."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Sending..." }}
      buttons={[
        { text: "Send", type: "submit", variant: "action" },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    >
      {!isAuthenticated && (
        <form.Field
          name="email"
          validators={validators.email}
          children={(field) => (
            <TextField field={field} label="What is your email address?" />
          )}
        />
      )}
      <form.Field
        name="type"
        validators={validators.type}
        children={(field) => (
          <ListField
            field={field}
            label="Select a category:"
            options={validators.type.onSubmit._def.values}
          />
        )}
      />
      <form.Field
        name="description"
        validators={validators.description}
        children={(field) => (
          <EditorField
            field={field}
            className="min-h-24"
            label="Tell us more:"
            hideToolbar
          />
        )}
      />
    </ModalContent>
  );
};
