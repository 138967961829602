export const directions = {
  asc: 1,
  desc: -1,
};

export const sortField = <I, K extends keyof I>(
  items: I[],
  {
    key,
    dir,
  }: {
    key: K | ((i: I) => string | number | null | undefined);
    dir: keyof typeof directions;
  },
) => {
  const dirN = directions[dir];

  return items.slice(0).sort((a, b) => {
    const aSort = typeof key === "function" ? key(a) : a[key];
    const bSort = typeof key === "function" ? key(b) : b[key];

    if (aSort === null || aSort === undefined) {
      return dirN;
    }
    if (bSort === null || bSort === undefined) {
      return dirN * -1;
    }

    if (typeof aSort === "string") {
      return aSort.localeCompare(`${bSort}`) * dirN;
    }
    if (typeof aSort === "number" && typeof bSort === "number") {
      return (aSort - bSort) * dirN;
    }

    return aSort > bSort ? dirN : dirN * -1;
  });
};
