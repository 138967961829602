import { CellContext } from "@tanstack/react-table";
import clsx from "clsx";

import { tw } from "../../assets";

export const CellImage = ({
  className,
  src,
  alt,
}: {
  className?: string;
  src: string;
  alt: string;
}) => (
  <img
    src={src}
    alt={alt}
    className={clsx("block size-5 object-contain", className)}
  />
);

export const imageCell = <D, V extends string, I extends Record<V, string>>({
  header,
  icons,
}: {
  header: string;
  icons: I;
}) => ({
  header,
  meta: { className: tw`w-1` },
  cell: (v: CellContext<D, V>) => (
    <CellImage
      alt={v.getValue()}
      src={icons[v.getValue()]}
      className="mx-auto"
    />
  ),
});
