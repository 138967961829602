import {
  IconBriefcase,
  IconHistory,
  IconIdBadge,
  IconMail,
  IconPhone,
  IconTrashX,
  IconUser,
  IconUserCog,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";

import { toTitle } from "@joy/shared-utils";

import { Entity, Time } from "../../components";
import { userName, userQuery } from "../../data";

export const UserInfoPanel = () => {
  const userId = useParams({
    from: "/_admin/users/$userId",
    select: (s) => s.userId,
  });
  const { data } = useSuspenseQuery(userQuery(userId));

  return (
    <Entity
      title="User Info"
      edit={{
        search: { action: "edit" },
        replace: true,
      }}
      item={data}
      details={[
        [
          [IconMail, "Email", (u) => u.email],
          [IconUser, "Name", (u) => userName(u, { hideEmail: true })],
          [IconPhone, "Phone", (u) => u.phone],
        ],
        [
          [IconBriefcase, "Company", (u) => u.company],
          [IconIdBadge, "Title", (u) => u.title],
        ],
        [
          [IconUserCog, "Joylevel", (u) => toTitle(u.jlteam || "user")],
          [
            IconHistory,
            "Last",
            (u) => <Time date={u.lastActiveAt} prefix="Acitve" />,
          ],
        ],
      ]}
      actions={[
        {
          search: { action: "delete" },
          kind: "link",
          variant: "danger",
          icon: IconTrashX,
          text: "Delete User",
        },
      ]}
    />
  );
};
