import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { CommentEntity, useDeleteComment } from "../../data";

export const CommentDeleteContent = ({
  entityType,
  entityId,
  commentId,
}: {
  entityType: CommentEntity;
  entityId: string;
  commentId?: string;
}) => {
  const { error, isPending, onDelete } = useDeleteComment(
    entityType,
    entityId,
    commentId,
  );

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete comment?`}
      description={`Are you sure you want to delete this comment? This action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Removing..." }}
      buttons={[
        { text: "Delete", type: "submit", variant: "danger", autoFocus: true },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
