import { StrapTable, strappingCalculate, strappingMax } from "./strapping.js";

export type TekUltrasonicOptions = {
  config: {
    mountToBottom: number;
    offsetVolume: number;
  };
  strappingTable: StrapTable;
};

export const tekUltrasonicHeight = (
  reading: number,
  { config: { mountToBottom } }: TekUltrasonicOptions,
) => mountToBottom - reading;

export const tekUltrasonicVolume = (
  reading: number,
  options: TekUltrasonicOptions,
) => {
  const volume = strappingCalculate(
    options.strappingTable,
    tekUltrasonicHeight(reading, options),
    "mm",
  );

  return volume ? volume + options.config.offsetVolume : undefined;
};

export const tekUltrasonicReverse = (
  volume: number,
  options: TekUltrasonicOptions,
) => {
  const height = strappingCalculate(
    options.strappingTable,
    volume - options.config.offsetVolume,
    "l",
  );
  return height ? options.config.mountToBottom - height : undefined;
};

export const tekUltrasonicLimit = (
  percent: number,
  options: TekUltrasonicOptions,
) => {
  const max = strappingMax(options.strappingTable, "l");
  if (!max) return undefined;
  const volume = (percent / 100) * max;
  const reading = tekUltrasonicReverse(volume, options);
  if (reading === undefined || reading < 0) return undefined;

  return Math.round(reading);
};

export const tekUltrasonicRate = (
  percent: number,
  period: number,
  options: TekUltrasonicOptions,
) => {
  const range = options.config.mountToBottom;
  const rate = (percent / 100) * range;
  return Math.max(Math.floor(rate / (period / 15)), 63);
};
