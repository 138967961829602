export type AwaitedObject<T> = {
  [K in keyof T]: Awaited<T[K]>;
};

export const awaitObject = async <T extends Record<string, unknown>>(
  obj: T,
): Promise<AwaitedObject<T>> => {
  const entries = await Promise.all(
    Object.entries(obj).map(async ([key, value]) => [key, await value]),
  );
  return Object.fromEntries(entries);
};

export const awaitBatch = async <T>(
  items: Promise<T>[],
): Promise<(T | Error)[]> =>
  (await Promise.allSettled(items)).map((r) =>
    r.status === "fulfilled" ? r.value : r.reason,
  );
