import { IconEdit, IconTrashX } from "@tabler/icons-react";
import { useNavigate } from "@tanstack/react-router";
import { ColumnHelper } from "@tanstack/react-table";

import { cellKinds, dateCell, menuCell } from "../../components";
import { ProductItem, usePrefetchProduct, userName } from "../../data";

export const productColumns =
  ({
    customerId,
    prefetchProduct,
    navigate,
  }: {
    customerId?: string;
    prefetchProduct: ReturnType<typeof usePrefetchProduct>;
    navigate: ReturnType<typeof useNavigate>;
  }) =>
  (c: ColumnHelper<ProductItem>) => [
    c.accessor("label", {
      header: "Product",
    }),
    c.accessor("colour", {
      header: "Colour",
      cell: (v) => (
        <div className="flex items-center gap-2">
          <div
            className="size-5 rounded"
            style={{ backgroundColor: v.getValue() }}
          />
          <span>{v.getValue()}</span>
        </div>
      ),
    }),
    c.accessor(
      "updatedAt",
      dateCell({
        header: "Updated",
      }),
    ),
    c.accessor("updatedBy", {
      header: "By",
      meta: { className: cellKinds.appears },
      cell: (v) => userName(v.getValue()),
    }),
    c.display(
      menuCell({
        onClick: ({ id }) => prefetchProduct(id),
        items: [
          {
            icon: IconEdit,
            text: "Update Product",
            variant: "standard",
            onClick: (p) =>
              navigate(
                customerId
                  ? {
                      to: "/customers/$customerId",
                      search: { action: "edit", product: p.id },
                      replace: true,
                      params: { customerId },
                    }
                  : {
                      to: "/library/products",
                      search: { action: "edit", product: p.id },
                      replace: true,
                    },
              ),
          },
          {
            icon: IconTrashX,
            text: "Remove Product",
            variant: "danger",
            onClick: (p) =>
              navigate(
                customerId
                  ? {
                      to: "/customers/$customerId",
                      search: { action: "delete", product: p.id },
                      replace: true,
                      params: { customerId },
                    }
                  : {
                      to: "/library/products",
                      search: { action: "delete", product: p.id },
                      replace: true,
                    },
              ),
          },
        ],
      }),
    ),
  ];
