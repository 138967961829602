import { DependencyList, useCallback, useEffect, useRef } from "react";

export const useVisibleItem = (
  fn: () => void,
  {
    rootMargin,
    threshold,
  }: Pick<IntersectionObserverInit, "rootMargin" | "threshold">,
  dependencies: DependencyList,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLTableRowElement | HTMLDivElement>(null);

  const runFn = useCallback<IntersectionObserverCallback>((entries) => {
    if (entries[0]?.isIntersecting) fn();
  }, dependencies);

  useEffect(() => {
    const observer = new IntersectionObserver(runFn, {
      root: ref.current,
      rootMargin,
      threshold,
    });
    if (itemRef.current) observer.observe(itemRef.current);

    return () => observer.disconnect();
  }, [runFn, ref.current, itemRef.current, rootMargin, threshold]);

  return { ref, itemRef };
};
