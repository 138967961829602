import { QueryClient } from "@tanstack/react-query";
import {
  ErrorComponentProps,
  Outlet,
  createRootRouteWithContext,
  useRouterState,
} from "@tanstack/react-router";
import clsx from "clsx";
import { useEffect, useState } from "react";

import {
  Breadcrumbs,
  ErrorComponent,
  Head,
  NotFound,
  Progress,
  Sidebar,
  scheme,
} from "../components";
import { AuthContext, useSubscriptions } from "../data";
import { GlobalPanel } from "../panels";

type RouterContext = {
  queryClient: QueryClient;
  auth: AuthContext;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: Component,
  notFoundComponent: () => (
    <NotFound
      item="Page"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  ),
  errorComponent: RootErrorComponent,
});

export function Component() {
  useSubscriptions();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isLoading = useRouterState({ select: (s) => s.isLoading });
  const path = useRouterState({ select: (s) => s.location.pathname });
  useEffect(() => setSidebarOpen(false), [path]);

  return (
    <>
      <div className="absolute inset-0 flex">
        <Head />
        <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
        <div
          className={clsx(
            "flex min-w-0 flex-1 flex-col overflow-auto",
            scheme.standard,
          )}
        >
          <Breadcrumbs setOpen={setSidebarOpen} />
          <main className="relative min-h-0 flex-1 p-3">
            <Outlet />
          </main>
        </div>
        <Progress
          position="top-0"
          background="bg-gradient-to-b from-sky-600/60"
          show={isLoading}
        />
      </div>
      <GlobalPanel />
    </>
  );
}

export function RootErrorComponent(props: ErrorComponentProps) {
  return <div className="absolute inset-0 flex">{ErrorComponent(props)}</div>;
}
