import { createFileRoute } from "@tanstack/react-router";

import { loginIcons } from "../../../assets";
import {
  Button,
  Form,
  FormError,
  LinkButton,
  Loading,
  TextField,
} from "../../../components";
import { useAuthForm } from "../../../data";

export const Route = createFileRoute("/_unauthenticated/login/")({
  component: Component,
  head: () => ({ meta: [{ title: "Sign In" }] }),
});

export function Component() {
  const { form, validators, loading, error, signIn } = useAuthForm({
    onSubmit: async ({ email, password }) => {
      await signIn.create({
        identifier: email,
      });
      await signIn.attemptFirstFactor({
        strategy: "password",
        password,
      });
      window.location.reload();
    },
  });

  return (
    <>
      <h2 className="text-2xl font-bold">Sign in to your account</h2>
      <p className="mt-2 text-sm text-gray-500">
        No account?{" "}
        <LinkButton
          to="/login/register"
          kind="link"
          variant="brand"
          text="Sign up now"
          replace
        />
      </p>
      <Form kind="column" onSubmit={form.handleSubmit}>
        <FormError error={error} />
        <form.Field
          name="email"
          validators={validators.email}
          children={(field) => (
            <TextField field={field} label="Email address" inputMode="email" />
          )}
        />
        <form.Field
          name="password"
          validators={validators.password}
          children={(field) => (
            <TextField
              field={field}
              label={
                <>
                  Password{" "}
                  <LinkButton
                    kind="link"
                    variant="brand"
                    text="Forgot?"
                    to="/login/forgot"
                  />
                </>
              }
              type="password"
            />
          )}
        />

        <Button kind="full" type="submit" variant="brand" text="Sign in" />
        <Loading show={loading} text="Signing in..." />
      </Form>
      <div className="mt-10">
        <div className="flex items-center gap-6">
          <div className="flex-1 border-t border-gray-200" />
          <div className="text-sm font-medium">Or continue with</div>
          <div className="flex-1 border-t border-gray-200" />
        </div>
        <div className="flex gap-4">
          <Button
            kind="full"
            variant="standard"
            text="Google"
            icon={({ className }) => (
              <img className={className} src={loginIcons.google} alt="" />
            )}
            onClick={() => {
              signIn.authenticateWithRedirect({
                strategy: "oauth_google",
                redirectUrl: window.origin,
                redirectUrlComplete: window.origin,
              });
            }}
          />
          <Button
            kind="full"
            variant="standard"
            text="Microsoft"
            icon={({ className }) => (
              <img className={className} src={loginIcons.microsoft} alt="" />
            )}
            onClick={() => {
              signIn.authenticateWithRedirect({
                strategy: "oauth_microsoft",
                redirectUrl: window.origin,
                redirectUrlComplete: window.origin,
              });
            }}
          />
        </div>
      </div>
    </>
  );
}
