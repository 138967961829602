import { IconTrashX } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { NavigateOptions, useNavigate } from "@tanstack/react-router";
import { Suspense } from "react";

import { Activity, Editor, EditorDisplay, Loading } from "../../components";
import {
  CommentEntity,
  commentsQuery,
  useAuth,
  useCreateComment,
} from "../../data";

export type CommentsPanelProps = {
  entityType: CommentEntity;
  entityId: string;
  onDelete: Pick<
    NavigateOptions & { search: { action: "delete"; comment: string } },
    "to" | "params"
  >;
};

export const CommentsPanelContent = ({
  entityType,
  entityId,
  onDelete,
}: CommentsPanelProps) => {
  const {
    client: { user },
  } = useAuth();
  const navigate = useNavigate();
  const comments = useSuspenseInfiniteQuery(
    commentsQuery(entityType, entityId),
  );
  const createComment = useCreateComment({
    entityType,
    entityId,
  });

  return (
    <Activity
      title="Activity"
      items={comments.data || []}
      action={<Editor key={createComment.id} {...createComment} small />}
      content={(item) => (
        <EditorDisplay value={item.message} className="text-sm text-gray-600" />
      )}
      actions={(item) =>
        item.createdBy.id === user?.id
          ? [
              {
                icon: IconTrashX,
                text: "Delete",
                variant: "danger",
                onClick: () =>
                  navigate({
                    ...onDelete,
                    search: { action: "delete", comment: item.id },
                    replace: true,
                  }),
              },
            ]
          : undefined
      }
    />
  );
};

export const CommentsPanel = (props: CommentsPanelProps) => (
  <Suspense fallback={<Loading show />}>
    <CommentsPanelContent {...props} />
  </Suspense>
);
