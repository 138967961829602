export type PropertyAccessor<
  ObjectType,
  Key extends keyof ObjectType,
> = Key extends string
  ? ObjectType[Key] extends Record<string, unknown>
    ? `${Key}.${PropertyAccessor<ObjectType[Key], keyof ObjectType[Key]>}`
    : Key
  : never;

export type NestedObjectType<T> = {
  [K in PropertyAccessor<
    NonNullable<T>,
    keyof T
  >]: K extends `${infer Head}.${infer Rest}`
    ? Head extends keyof T
      ? Rest extends PropertyAccessor<NonNullable<T[Head]>, keyof T[Head]>
        ? NestedObjectType<T[Head]>[Rest]
        : never
      : never
    : K extends keyof T
      ? T[K]
      : never;
};

export type RecursiveNonNullable<T> = {
  [K in keyof T]-?: RecursiveNonNullable<NonNullable<T[K]>>;
};

export type NestedObjectKeys<T> = keyof NestedObjectType<
  RecursiveNonNullable<T>
>;

export function getValue<
  T,
  K extends keyof NestedObjectType<RecursiveNonNullable<T>>,
>(
  obj: T,
  accessor: K,
): NestedObjectType<RecursiveNonNullable<T>>[K] | undefined {
  const pathParts = accessor.split(".");
  let result: any = obj;

  for (const pathPart of pathParts) {
    if (typeof result === "object" && result !== null) {
      result = result[pathPart];
    } else {
      return undefined;
    }
  }

  return result as NestedObjectType<RecursiveNonNullable<T>>[K];
}
