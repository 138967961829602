import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { differenceInMinutes } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";

import { SubscriptionResponse, subscriptionSchema } from "@joy/shared-utils";

import { getAuthToken } from "../auth";
import { accountQuery, accountTanksQuery, accountsQuery } from "./accounts";
import { commentsQuery } from "./comments";
import {
  customerAccountsQuery,
  customerQuery,
  customersQuery,
} from "./customers";
import { documentQuery, documentsQuery } from "./documents";
import { gaugeSettingsQuery } from "./gauge.settings";
import { gaugeObservationQuery, gaugeQuery, gaugesQuery } from "./gauges";
import { CommentEntity, TankQuery } from "./operations.generated";
import { planQuery, plansQuery } from "./plans";
import { roleQuery, rolesQuery, userRolesQuery } from "./roles";
import { tankAlertsQuery, tankQuery, tanksQuery } from "./tanks";

const handleEntity = async (
  queryClient: QueryClient,
  { updated, parent, sibling }: SubscriptionResponse & { type: "entity" },
) => {
  const updateTankLists = async (tank: TankQuery["tank"]) => {
    if (tank) {
      queryClient.setQueryData(tanksQuery().queryKey, (existing) => {
        if (!existing) return existing;

        return {
          ...existing,
          pages: existing.pages.map((page) => {
            if (page.tanks.tanks.find((t) => t.id === tank.id)) {
              return {
                ...page,
                tanks: {
                  ...page.tanks,
                  tanks: page.tanks.tanks.map((t) =>
                    t.id === tank.id ? tank : t,
                  ),
                },
              };
            }
            return page;
          }),
        };
      });
      queryClient.setQueryData(
        accountTanksQuery(parent.id).queryKey,
        (existing) => {
          if (!existing) return existing;

          return {
            ...existing,
            pages: existing.pages.map((page) => {
              if (page.account?.tanks.tanks.find((t) => t.id === tank.id)) {
                return {
                  ...page,
                  tanks: {
                    ...page.account.tanks,
                    tanks: page.account.tanks.tanks.map((t) =>
                      t.id === tank.id ? tank : t,
                    ),
                  },
                };
              }
              return page;
            }),
          };
        },
      );
    }
  };

  switch (updated.type) {
    case "document": {
      await Promise.all([
        queryClient.invalidateQueries(documentsQuery()),
        queryClient.invalidateQueries(documentQuery(updated.id)),
      ]);
      break;
    }
    case "plan": {
      await Promise.all([
        queryClient.invalidateQueries(plansQuery()),
        queryClient.invalidateQueries(planQuery(updated.id)),
      ]);
      break;
    }
    case "customer": {
      await Promise.all([
        queryClient.invalidateQueries(customersQuery()),
        queryClient.invalidateQueries(customerQuery(updated.id)),
      ]);
      break;
    }
    case "account": {
      await Promise.all([
        queryClient.invalidateQueries(accountsQuery()),
        queryClient.invalidateQueries(accountQuery(updated.id)),
        queryClient.invalidateQueries(customerAccountsQuery(parent.id)),
      ]);
      break;
    }
    case "comment": {
      await queryClient.invalidateQueries(
        commentsQuery(parent.type as CommentEntity, parent.id),
      );
      break;
    }
    case "role": {
      await Promise.all([
        (parent.type === "account" || parent.type === "customer") &&
          queryClient.invalidateQueries(rolesQuery(parent.id, parent.type)),
        sibling &&
          (parent.type === "account" || parent.type === "customer") &&
          queryClient.invalidateQueries(
            userRolesQuery(sibling.id, parent.type),
          ),
        queryClient.invalidateQueries(roleQuery(updated.id)),
      ]);
      break;
    }
    case "tank": {
      await queryClient.invalidateQueries(tankQuery(updated.id));
      const { tank } = await queryClient.fetchQuery(tankQuery(updated.id));
      await updateTankLists(tank);

      break;
    }
    case "level": {
      await queryClient.invalidateQueries(tankQuery(parent.id));
      await queryClient.invalidateQueries({
        queryKey: ["tank-level", parent.id],
      });
      const { tank } = await queryClient.fetchQuery(tankQuery(parent.id));
      await updateTankLists(tank);

      break;
    }
    case "alert": {
      await queryClient.invalidateQueries(tankQuery(parent.id));
      await queryClient.invalidateQueries(tankAlertsQuery(parent.id));
      break;
    }
    case "gauge": {
      await Promise.all([
        queryClient.invalidateQueries(gaugesQuery()),
        queryClient.invalidateQueries(gaugeQuery(updated.id)),
        queryClient.invalidateQueries(gaugeSettingsQuery(updated.id)),
      ]);
      break;
    }
    case "gauge-response":
    case "gauge-setting": {
      await Promise.all([
        queryClient.invalidateQueries(gaugeSettingsQuery(updated.id)),
      ]);
      break;
    }
    case "observation": {
      await Promise.all([
        queryClient.invalidateQueries(gaugeQuery(parent.id)),
        queryClient.invalidateQueries(gaugeObservationQuery(parent.id)),
      ]);
      break;
    }
  }
};

export const useSubscriptions = () => {
  const [ws, setWS] = useState<WebSocket>();
  const keepalive = useRef<Date>();
  const initialized = useRef(false);
  const queryClient = useQueryClient();

  const resetWS = useCallback(() => {
    keepalive.current = undefined;
    if (ws && (ws.readyState === ws.OPEN || ws.readyState === ws.CONNECTING))
      ws.close();

    (async () => {
      const token = await getAuthToken();
      if (token) {
        const socket = new WebSocket(`wss://${window.location.host}/ws`, [
          "events",
          token || "",
        ]);
        socket.onopen = () => {
          keepalive.current = new Date();
        };

        setWS(socket);
      }
    })();
  }, [ws]);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    resetWS();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (ws && ws.readyState === ws.OPEN) {
        if (
          keepalive.current &&
          differenceInMinutes(new Date(), keepalive.current) >= 4
        ) {
          keepalive.current = new Date();
          ws.send(JSON.stringify({ type: "ka" }));
        }
      } else {
        resetWS();
      }
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [ws, resetWS]);

  useEffect(() => {
    if (!ws) return;

    const onMessage = (message: MessageEvent) => {
      try {
        const parsed = subscriptionSchema.parse(JSON.parse(message.data));
        if (parsed.type === "entity") handleEntity(queryClient, parsed);
      } catch (e) {
        console.error("Unexpected WS message", e);
      }
    };

    ws.addEventListener("message", onMessage);

    return () => {
      ws.removeEventListener("message", onMessage);
    };
  }, [ws, queryClient]);

  return null;
};
