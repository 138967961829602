import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { DocumentCreateContent } from "./create";

export const DocumentsActionPanel = () => {
  const { Slide } = useMatch({
    from: "/_admin/library/documents/",
    select: (s) => {
      switch (s.search.action) {
        case "create":
          return { Slide: DocumentCreateContent };
      }

      return {};
    },
  });

  return <Actions slide={Slide && <Slide />} />;
};
