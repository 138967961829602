import { Button } from "@headlessui/react";
import {
  IconAlignLeft,
  IconLayoutDashboard,
  IconMessageReport,
} from "@tabler/icons-react";
import { Link, useRouterState } from "@tanstack/react-router";
import { Fragment, SetStateAction } from "react";

import { useAuth } from "../../data";
import { useGlobalPanel } from "../../hooks";

export type BreadcrumbsProps = {
  setOpen: (open: SetStateAction<boolean>) => void;
};

export const Breadcrumbs = ({ setOpen }: BreadcrumbsProps) => {
  const { captureScreenshot, setPanel } = useGlobalPanel();
  const { isAuthenticated } = useAuth();
  const parts = useRouterState({
    select: (s) =>
      s.matches
        .map((m) => ({
          label: m.meta?.find((t) => t?.title)?.title,
          to: m.pathname,
          params: m.params,
        }))
        .filter((p) => p.label),
  });

  if (!isAuthenticated) return null;

  return (
    <aside className="z-10 flex items-center px-3 text-gray-700">
      <Button
        className="mr-4 flex size-10 shrink-0 items-center justify-center lg:hidden"
        aria-label="Toggle Sidebar"
        onClick={() => setOpen((o) => !o)}
      >
        <IconAlignLeft size={30} />
      </Button>
      <nav
        aria-label="Breadcrumbs"
        className="my-3 flex flex-1 items-center gap-1 overflow-hidden"
      >
        <Link to="/" className="p-1">
          <IconLayoutDashboard size={24} stroke={1.6} role="presentation" />
          <span className="sr-only">Home</span>
        </Link>
        {parts.map(({ label, ...props }) => (
          <Fragment key={label}>
            <svg
              className="-m-2 size-6 shrink-0 text-gray-300"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <Link
              {...props}
              className="shrink truncate p-1 text-sm font-medium transition hover:text-gray-700"
            >
              {label}
            </Link>
          </Fragment>
        ))}
      </nav>
      <Button
        className="ml-4 flex size-10 shrink-0 items-center justify-center lg:hidden"
        aria-label="Help & Support"
        onClick={() => {
          captureScreenshot();
          setPanel("feedback");
        }}
      >
        <IconMessageReport />
      </Button>
    </aside>
  );
};
