import { queryOptions } from "@tanstack/react-query";

import { liveGeocode } from "./live";
import { localGeocode } from "./local";

export const geocode = is_local ? localGeocode : liveGeocode;

export const geocodeQuery = (
  session: string,
  search: string,
  proximity?: Promise<{ latitude: number; longitude: number }>,
) =>
  queryOptions({
    queryKey: ["geocode", session, search],
    queryFn: () => geocode.suggest(search, session, proximity),
    staleTime: 1000 * 60 * 60,
    enabled: !!search,
  });

export * from "./util";
