import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { useDisableGauge } from "../../data";

export const GaugeDisableContent = ({ gaugeId }: { gaugeId: string }) => {
  const { data, error, isPending, onDisable } = useDisableGauge(gaugeId);

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Disable "${data.name}"?`}
      description={`Are you sure you want to disable "${data.name}"? If you proceed, the only way to enable it again is manual on-site activation.`}
      error={error}
      onSubmit={onDisable}
      loading={{ show: isPending, text: "Disabling..." }}
      buttons={[
        { text: "Disable", type: "submit", variant: "danger", autoFocus: true },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
