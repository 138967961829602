import {
  IconAntennaBars1,
  IconAntennaBars2,
  IconAntennaBars3,
  IconAntennaBars4,
  IconAntennaBars5,
} from "@tabler/icons-react";

export const SignalStrengthDisplay = ({
  className,
  csq,
}: {
  className?: string;
  csq: number;
}) => {
  let Icon = IconAntennaBars1;
  if (csq >= 20) Icon = IconAntennaBars5;
  else if (csq >= 15) Icon = IconAntennaBars4;
  else if (csq >= 10) Icon = IconAntennaBars3;
  else if (csq >= 2) Icon = IconAntennaBars2;

  return <Icon className={className} />;
};

export const displaySignalStrength = (
  csq: number | null | undefined,
  className?: string,
) => {
  if (typeof csq !== "number") return undefined;
  return <SignalStrengthDisplay csq={csq} className={className} />;
};
